
import { types } from 'mobx-state-tree';

export default types
  .model('RatePlanCharge', {
    applyDiscountTo: types.maybeNull(types.string),
    // billingDay: types.maybeNull(types.string),
    // billingPeriod: types.maybeNull(types.string),
    // billingPeriodAlignment: types.maybeNull(types.string),
    // billingTiming: types.maybeNull(types.string),
    // chargedThroughDate: types.maybeNull(types.string),
    currency: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    discountAmount: types.maybeNull(types.union(types.number, types.string)),
    // // discountApplyDetails: [] TODO - don't have info on how it looks like
    // discountClass: types.maybeNull(types.string),
    // discountLevel: types.maybeNull(types.string),
    discountPercentage: types.maybeNull(types.union(types.number, types.string)),
    // dmrc: types.maybeNull(types.number),
    // done: types.maybeNull(types.boolean),
    // dtcv: types.maybeNull(types.number),
    effectiveEndDate: types.maybeNull(types.string),
    // effectiveStartDate:  types.maybeNull(types.string),
    // endDateCondition:  types.maybeNull(types.string),
    // id:  types.maybeNull(types.string),
    // includedUnits: types.maybeNull(types.string),
    // listPriceBase: types.maybeNull(types.string),
    // model:  types.maybeNull(types.string),
    // mrr: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    // number:  types.maybeNull(types.string),
    // numberOfPeriods: types.maybeNull(types.string),
    // originalChargeId:  types.maybeNull(types.string),
    // overageCalculationOption: types.maybeNull(types.string),
    // overagePrice: types.maybeNull(types.string),
    // overageUnusedUnitsCreditOption: types.maybeNull(types.string),
    price: types.maybeNull(types.number),
    // priceChangeOption: types.maybeNull(types.string),
    // priceIncreasePercentage: types.maybeNull(types.string),
    // pricingSummary:  types.maybeNull(types.string),
    // processedThroughDate: types.maybeNull(types.string),
    // productRatePlanChargeId: types.maybeNull(types.string),
    quantity: types.maybeNull(types.number),
    // segment: types.maybeNull(types.number),
    // smoothingModel: types.maybeNull(types.string),
    // specificBillingPeriod: types.maybeNull(types.string),
    // specificEndDate: types.maybeNull(types.string),
    tcv: types.maybeNull(types.number),
    // tiers: types.maybeNull(types.string),
    // triggerDate: types.maybeNull(types.string),
    // triggerEvent:  types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    // unusedUnitsCreditRates: types.maybeNull(types.string),
    // uom: types.maybeNull(types.string),
    // upToPeriods: types.optional(types.string, ''),
    // upToPeriodsType: types.maybeNull(types.string),
    // usageRecordRatingOption: types.maybeNull(types.string),
    // version: types.maybeNull(types.number),
    latestCatalogPrice: types.maybeNull(types.number),
  });
