import { types } from 'mobx-state-tree';
import Bundle from './Bundle';
import Membership from './Membership';
import Pod from './Pod';
import ShippingFee from './ShippingFee';

export const Products = types
  .model('Products', {
    bundle1SP: types.maybeNull(Bundle),
    // bundleOne: types.maybeNull(Bundle),
    // bundleTwo: types.maybeNull(Bundle),
    // bundleThree: types.maybeNull(Bundle),
    // bundleFour: types.maybeNull(Bundle),
    // bundleTalkTalk: types.maybeNull(Bundle),
    pod: types.maybeNull(Pod),
    superPod: types.maybeNull(Pod),
    powerPod: types.maybeNull(Pod),
    smartThings: types.maybeNull(Pod),
    memberships: types.optional(types.array(Membership), []),
    shippingFees: types.optional(types.array(ShippingFee), []),
    loading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    shippingMethods() {
      // Trigger estimated arrival acquisition
      self.shippingFees.forEach((sf) => {
        sf.getEstimatedArrival();
      });

      // Filter and sort the methods
      return [...self.shippingFees];
    },
    setLoading(value) {
      self.loading = value;
    },
  }));
