import { types } from 'mobx-state-tree';
import ProductRatePlanCharge from './ProductRatePlanCharge';

export default types
  .model('ProductRatePlan', {
    color: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    id: types.string,
    name: types.string,
    podCount: types.number,
    productRatePlanCharges: types.optional(types.array(ProductRatePlanCharge), []),
    superPodCount: types.number,
  });
