import axios from 'axios';

const NEWSLETTER_ENDPOINT = 'https://forms.hubspot.com/uploads/form/v2/3848927/';

export const postNewsletterSubscription = async (data, formId) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${NEWSLETTER_ENDPOINT}${formId}`,
      responseType: 'json',
      headers: {
        'content-type': 'application/x-www-form-urlencoded;',
      },
      data,
    });
    return response.data;
  } catch (err) {
    console.error('Error happened:', err);
    return err;
  }
};
