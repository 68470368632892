import { types, getRoot } from 'mobx-state-tree';
import moment from 'moment';
import {
  getBundleType,
  extractPriceFromProduct,
  getGalleryImages,
  extractColorFromProduct,
} from 'utils/bundles';
import ProductRatePlan from './ProductRatePlan';

const DEFAULT_SHIPPING_DATE = '';
const BACKORDERED = 'backordered';

export default types
  .model('Bundle', {
    localId: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    id: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    productRatePlans: types.optional(types.array(ProductRatePlan), []),
    sku: types.optional(types.string, ''),
    typeId: types.optional(types.string, ''),
  })
  .views(self => ({
    get label() {
      return getBundleType(self.typeId)[getRoot(self).country].label;
    },
    get bundleName() {
      return getBundleType(self.typeId)[getRoot(self).country].bundleName;
    },
    get bagImageBaseNameUrl() {
      return getBundleType(self.typeId)[getRoot(self).country].bagImageBaseNameUrl;
    },
    get pricingImageUrl() {
      return getBundleType(self.typeId)[getRoot(self).country].pricingImageUrl;
    },
    get pricingImageUrlWebp() {
      return getBundleType(self.typeId)[getRoot(self).country].pricingImageUrlWebp;
    },
    get imageBaseNameUrl() {
      return getBundleType(self.typeId)[getRoot(self).country].imageBaseNameUrl;
    },
    get extractedPrice() {
      return extractPriceFromProduct(self);
    },
    get color() {
      return extractColorFromProduct(self);
    },
    get hardwarePrice() {
      return extractPriceFromProduct(self);
    },
    get nPod() {
      return getBundleType(self.typeId)[getRoot(self).country].podCount;
    },
    get nSuperPod() {
      return getBundleType(self.typeId)[getRoot(self).country].superPodCount;
    },
    get speed() {
      return self.bundleName;
    },
    get supportedColors() {
      return getBundleType(self.typeId)[getRoot(self).country].supportedColors;
    },
    get totalPrice() {
      return extractPriceFromProduct(self);
    },
    get displayPrice() {
      const { displayPrice } = getBundleType(self.typeId)[getRoot(self).country];
      if (typeof displayPrice !== 'undefined') {
        return displayPrice;
      }
      return self.totalPrice;
    },
    get toObject() {
      // To prepare the bundle form to conform to the current backend
      const typeData = getBundleType(self.typeId)[getRoot(self).country];
      return {
        id: typeData.id,
        nSuperPod: typeData.superPodCount,
        nPod: typeData.podCount,
        hardwarePrice: self.hardwarePrice,
        totalPrice: self.totalPrice,
      };
    },
  })).actions(self => ({
    galleryImages(color) {
      return getGalleryImages(getBundleType(self.typeId)[getRoot(self).country], color);
    },

    isBackordered(color) {
      const ratePlan = self.productRatePlans.toJSON().find(rp => rp.color === color);
      if (ratePlan && ratePlan.productRatePlanCharges[0]) {
        const stockStatus = ratePlan.productRatePlanCharges[0].StockStatus__c;

        if (stockStatus && stockStatus.toLowerCase().includes(BACKORDERED)) {
          return true;
        }
      }
      return false;
    },

    shippingDate(color) {
      const ratePlan = self.productRatePlans.toJSON().find(rp => rp.color === color);

      if (ratePlan && ratePlan.productRatePlanCharges[0]) {
        const inStock = ratePlan.productRatePlanCharges[0].InStock__c;
        const stockStatus = ratePlan.productRatePlanCharges[0].StockStatus__c;

        if (stockStatus && stockStatus.toLowerCase().includes(BACKORDERED) && inStock) {
          return `Backordered until ${moment(inStock).format('MMMM D, YYYY')}`;
        }

        if (stockStatus && stockStatus.toLowerCase().includes(BACKORDERED)) {
          return 'Backordered';
        }

        if (stockStatus && stockStatus.toLowerCase().includes('delayed_shipment') && inStock) {
          return `Ships ${moment(inStock).format('MMM D')}`;
        }

        return stockStatus || DEFAULT_SHIPPING_DATE;
      }
      return DEFAULT_SHIPPING_DATE;
    },
  }));
