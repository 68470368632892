import { types } from 'mobx-state-tree';

import RatePlanCharge from './RatePlanCharge';
import RatePlanMeta from './RatePlanMeta';

export default types
  .model('RatePlan', {
    id: types.maybeNull(types.string),
    lastChangeType: types.maybeNull(types.string),
    productId: types.maybeNull(types.string),
    productName: types.maybeNull(types.string),
    productSku: types.maybeNull(types.string),
    productRatePlanId: types.maybeNull(types.string),
    ratePlanName: types.maybeNull(types.string),
    ratePlanCharges: types.optional(types.array(RatePlanCharge), []),
    __metaMap: types.maybeNull(RatePlanMeta),
  });
