/* eslint-disable */
import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import { BUNDLE_TYPE_INDEX } from 'utils/bundles';
import { PRODUCT_TYPE_INDEX } from 'utils/products';
import { fetchProducts } from 'api/products';
import { getServerSession } from 'api/session';
import { getInvoiceData } from 'api/invoice';
import { lngFromReq } from 'next-i18next/dist/commonjs/utils';
import { fetchAccount, fetchInvoices, fetchCouponData } from 'api';

export const setProductTypeIds = (productData) => {
  if (productData.bundle1SP) { productData.bundle1SP.typeId = BUNDLE_TYPE_INDEX.SP; }
  if (productData.bundleOne) { productData.bundleOne.typeId = BUNDLE_TYPE_INDEX.BASIC; }
  if (productData.bundleTwo) { productData.bundleTwo.typeId = BUNDLE_TYPE_INDEX.FAST; }
  if (productData.bundleThree) { productData.bundleThree.typeId = BUNDLE_TYPE_INDEX.SUPER_FAST; }
  if (productData.bundleFour) { productData.bundleFour.typeId = BUNDLE_TYPE_INDEX.ULTRA_FAST; }
  if (productData.bundleTalkTalk) { productData.bundleTalkTalk.typeId = BUNDLE_TYPE_INDEX.UK; }
  if (productData.smartThings) { productData.smartThings.typeId = PRODUCT_TYPE_INDEX.SMART_THINGS; }

  if (productData.pod) productData.pod.typeId = PRODUCT_TYPE_INDEX.POD;
  if (productData.superPod) { productData.superPod.typeId = PRODUCT_TYPE_INDEX.SUPER_POD; }
  if (productData.powerPod) { productData.powerPod.typeId = PRODUCT_TYPE_INDEX.POWER_POD; }

  return productData;
};

export const getDefaultShippingMethod = (country) => {
  switch (country) {
    case 'us':
      return 'standard';
    case 'uk':
      return 'international';
    default:
      return 'standard';
  }
};

export const getInitialSnapshot = async (req, res, pathname, query) => {
  const isServer = !process.browser;
  const cookies = new Cookies(isServer ? req.headers.cookie : document.cookie);

  const accessToken = cookies.get('accessToken');
  const isLoggedIn = accessToken;

  let products = null;
  let session = null;
  let invoiceStore = null;
  let account = null;
  let invoices = null;
  let referralCouponData = null;

  if (isServer) {
    const i18lang = lngFromReq(req);
    let country = i18lang || 'us'; // TODO: find a better way to enforce default lang

    /* Set default Membership and Bundle only for non-logged users.
     * session has only checkout, so rely on cookie
     */
    if (!isLoggedIn) {
      // do nothing
    } else {
      // try {
      //   account = await fetchAccount(accessToken, country);
      //   invoices = await fetchInvoices(accessToken, country);
      //   const accountReferralCode = account
      //     && account.basicInfo
      //     && account.basicInfo.Referral_Code__c;
      //   if (accountReferralCode) {
      //     try {
      //       referralCouponData = await fetchCouponData(accountReferralCode); // TODO remove from SSR
      //     } catch (err) {
      //       //
      //     }
      //   }
      // } catch (error) {
      //   account = undefined;
      //   invoices = [];
      // }
    }

    // Reset loading flags
    return {
      i18nLanguage: country,
      ...(invoiceStore && { invoiceStore: invoiceStore.invoice }),
      ...(isLoggedIn && { authStore: { accessToken } }),
      accountStore: {
        referralCoupon: referralCouponData,
        accounts: {
          [country]: {
            accountData: account,
            loading: false,
          },
        },
        invoices: {
          [country]: {
            ...invoices,
            loading: false,
            loaded: account !== undefined,
          },
        },
      },
    };
  }

  return null;
};

export const triggerOnFormChange = (form, cb) => {
  form.fields._data.forEach(f => form.observe({
    path: f.value.name,
    key: 'value',
    call: () => {
      form.validate().then(() => cb(form.values(), form.isValid));
    },
  }));
};


export const validateOnFormChange = (form, cb) => {
  form.fields._data.forEach(() => form.validate().then(() => cb(form.values(), form.isValid)));
};
