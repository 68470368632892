import React, { Component } from 'react';

export default class IconSpin extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 33 33"
        className={this.props.className}
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke={this.props.strokeColor ? this.props.strokeColor : '#231F20'}
          strokeLinecap="round"
        >
          <path d="M32.465 16.605c0 8.616-6.984 15.6-15.599 15.6S1.267 25.22 1.267 16.604c0-8.615 6.984-15.599 15.6-15.599 6.325 0 11.771 3.766 14.22 9.177" />
          <path d="M25.562 9.51l5.72.875 1.183-5.955" />
        </g>
      </svg>
    );
  }
}
