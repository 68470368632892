export const POD_COLORS = {
  SILVER: {
    id: 'silver',
    color: '#E1E2E3',
    label: 'Silver',
    order: 1,
  },
  CHAMPAGNE: {
    id: 'champagne',
    color: '#e6e2d8',
    label: 'Champagne',
    order: 2,
  },
  PLUM: {
    id: 'plum',
    color: '#d9c7ce',
    label: 'Plum',
    order: 3,
  },
  WALNUT: {
    id: 'walnut',
    color: '#c4aca3',
    label: 'Walnut',
    order: 4,
  },
};

export const BUNDLE_TYPE_INDEX = {
  SP: 'bundle1SP',
  BASIC: 'bundleOne',
  FAST: 'bundleTwo',
  SUPER_FAST: 'bundleThree',
  ULTRA_FAST: 'bundleFour',
  NONE: 'bundleNone',
  UK: 'bundleTalkTalk',
};

const BUNDLE_PACKS = {
  us: {
    small: {
      id: 'small', label: '1-2 BR Home', bundles: [BUNDLE_TYPE_INDEX.BASIC],
    },
    medium: {
      id: 'medium', label: '3-4 BR Home', bundles: [BUNDLE_TYPE_INDEX.FAST, BUNDLE_TYPE_INDEX.SUPER_FAST],
    },
    large: {
      id: 'large', label: '5+ BR Home', bundles: [BUNDLE_TYPE_INDEX.ULTRA_FAST],
    },
  },
  uk: {
    small: {
      id: 'small', label: '1-2 BR Home', bundles: [BUNDLE_TYPE_INDEX.BASIC],
    },
    medium: {
      id: 'medium', label: '3-4 BR Home', bundles: [BUNDLE_TYPE_INDEX.SUPER_FAST],
    },
  },
  de: {
    small: {
      id: 'small', label: '1-2 BR Home', bundles: [BUNDLE_TYPE_INDEX.BASIC],
    },
    medium: {
      id: 'medium', label: '3-4 BR Home', bundles: [BUNDLE_TYPE_INDEX.SUPER_FAST],
    },
  },
};

export const ADD_ON_POD_COLORS = [
  POD_COLORS.SILVER,
  POD_COLORS.CHAMPAGNE,
];
export const SUPER_POD_COLORS = [
  POD_COLORS.SILVER,
  POD_COLORS.CHAMPAGNE,
  // POD_COLORS.PLUM,
  // POD_COLORS.WALNUT,
];
export const UK_COLORS = [POD_COLORS.SILVER];

export const BUNDLE_TYPES = {
  bundle1SP:
  {
    us: {
      id: 99,
      bundleName: null,
      superPodCount: 1,
      imageBaseNameUrl: 'single-checkout-superpod',
      bagImageBaseNameUrl: 'single-checkout-superpod',
      pricingImageUrl: '/static/images/pricing-images/1-superpod-2-pods.png',
      pricingImageUrlWebp: '/static/images/pricing-images/1-superpod-2-pods.webp',
      galleryImageUrl: '',
      supportedColors: ADD_ON_POD_COLORS,
      label: '1 SuperPod',
    },
    uk: {
      id: 99,
      superPodCount: 1,
      imageBaseNameUrl: 'single-checkout-superpod',
      bagImageBaseNameUrl: 'single-checkout-superpod',
      pricingImageUrl: '/static/images/pricing-images/1-superpod-2-powerpods.png',
      pricingImageUrlWebp: '/static/images/pricing-images/1-superpod-2-powerpods.webp',
      galleryImageUrl: '',
      supportedColors: ADD_ON_POD_COLORS,
      label: '1 SuperPod',
    },
    be: {
      id: 99,
      superPodCount: 1,
      imageBaseNameUrl: 'single-checkout-superpod',
      bagImageBaseNameUrl: 'single-checkout-superpod',
      pricingImageUrl: '/static/images/pricing-images/1-superpod-2-powerpods.png',
      pricingImageUrlWebp: '/static/images/pricing-images/1-superpod-2-powerpods.webp',
      galleryImageUrl: '',
      supportedColors: ADD_ON_POD_COLORS,
      label: '1 SuperPod',
    },
    ch: {
      id: 99,
      superPodCount: 1,
      imageBaseNameUrl: 'single-checkout-superpod',
      bagImageBaseNameUrl: 'single-checkout-superpod',
      pricingImageUrl: '/static/images/pricing-images/1-superpod-2-powerpods.png',
      pricingImageUrlWebp: '/static/images/pricing-images/1-superpod-2-powerpods.webp',
      galleryImageUrl: '',
      supportedColors: [POD_COLORS.SILVER],
      label: '1 SuperPod',
    },
  },
  bundleOne:
  {
    us: {
      id: 1,
      bundleName: null,
      podCount: 2,
      superPodCount: 1,
      imageBaseNameUrl: 'bundle-01-small',
      bagImageBaseNameUrl: 'bundle-checkout-small',
      pricingImageUrl: '/static/images/pricing-images/1-superpod-2-pods.png',
      pricingImageUrlWebp: '/static/images/pricing-images/1-superpod-2-pods.webp',
      galleryImageUrl: '',
      supportedColors: ADD_ON_POD_COLORS,
      label: '1 SuperPod & 2 Pods',
    },
    uk: {
      id: 1,
      podCount: 0,
      superPodCount: 1,
      powerPodCount: 2,
      imageBaseNameUrl: 'bundle-uk-01-small',
      bagImageBaseNameUrl: 'bundle-uk-checkout-small',
      pricingImageUrl: '/static/images/pricing-images/1-superpod-2-powerpods.png',
      pricingImageUrlWebp: '/static/images/pricing-images/1-superpod-2-powerpods.webp',
      galleryImageUrl: '',
      supportedColors: ADD_ON_POD_COLORS,
      label: '1 SuperPod & 2 PowerPods',
    },
    de: {
      id: 1,
      podCount: 0,
      superPodCount: 1,
      powerPodCount: 2,
      imageBaseNameUrl: 'bundle-uk-01-small',
      bagImageBaseNameUrl: 'bundle-uk-checkout-small',
      pricingImageUrl: '/static/images/pricing-images/1-superpod-2-powerpods.png',
      pricingImageUrlWebp: '/static/images/pricing-images/1-superpod-2-powerpods.webp',
      galleryImageUrl: '',
      supportedColors: ADD_ON_POD_COLORS,
      label: '1 SuperPod & 2 PowerPods',
    },
  },
  bundleTwo:
  {
    us: {
      id: 2,
      bundleName: 'Fast',
      podCount: 4,
      superPodCount: 1,
      imageBaseNameUrl: 'bundle-02-fast',
      bagImageBaseNameUrl: 'bundle-checkout-fast',
      pricingImageUrl: '/static/images/pricing-images/1-superpod-4-pods.png',
      pricingImageUrlWebp: '/static/images/pricing-images/1-superpod-4-pods.webp',
      supportedColors: ADD_ON_POD_COLORS,
      label: '1 SuperPod & 4 Pods',
    },
    uk: {
      id: 2,
      bundleName: 'Fast',
      podCount: 4,
      superPodCount: 1,
      imageBaseNameUrl: 'bundle-02-fast',
      bagImageBaseNameUrl: 'bundle-checkout-fast',
      pricingImageUrl: '/static/images/pricing-images/1-super-pod-4-pod.png',
      pricingImageUrlWebp: '/static/images/pricing-images/1-super-pod-4-pod.webp',
      supportedColors: ADD_ON_POD_COLORS,
      label: '1 SuperPod & 4 Pods',
    },
    de: {
      id: 2,
      bundleName: 'Fast',
      podCount: 4,
      superPodCount: 1,
      imageBaseNameUrl: 'bundle-02-fast',
      bagImageBaseNameUrl: 'bundle-checkout-fast',
      pricingImageUrl: '/static/images/pricing-images/1-super-pod-4-pod.png',
      pricingImageUrlWebp: '/static/images/pricing-images/1-super-pod-4-pod.webp',
      supportedColors: ADD_ON_POD_COLORS,
      label: '1 SuperPod & 4 Pods',
    },
  },
  bundleThree:
  {
    us: {
      id: 3,
      bundleName: 'SuperFast',
      podCount: 0,
      superPodCount: 3,
      imageBaseNameUrl: 'bundle-02-superfast',
      bagImageBaseNameUrl: 'bundle-checkout-superfast',
      pricingImageUrl: '/static/images/pricing-images/3-superpods.png',
      pricingImageUrlWebp: '/static/images/pricing-images/3-superpods.webp',
      supportedColors: SUPER_POD_COLORS,
      label: '3 SuperPods',
    },
    uk: {
      id: 3,
      bundleName: 'SuperFast',
      podCount: 0,
      superPodCount: 3,
      imageBaseNameUrl: 'bundle-uk-02-superfast',
      bagImageBaseNameUrl: 'bundle-checkout-superfast',
      pricingImageUrl: '/static/images/pricing-images/3-superpods-uk.png',
      pricingImageUrlWebp: '/static/images/pricing-images/3-superpods-uk.webp',
      supportedColors: [POD_COLORS.SILVER, POD_COLORS.CHAMPAGNE],
      label: '3 SuperPods',
    },
    de: {
      id: 3,
      bundleName: 'SuperFast',
      podCount: 0,
      superPodCount: 3,
      imageBaseNameUrl: 'bundle-uk-02-superfast',
      bagImageBaseNameUrl: 'bundle-checkout-superfast',
      pricingImageUrl: '/static/images/pricing-images/3-superpods-uk.png',
      pricingImageUrlWebp: '/static/images/pricing-images/3-superpods-uk.webp',
      supportedColors: [POD_COLORS.SILVER, POD_COLORS.CHAMPAGNE],
      label: '3 SuperPods',
    },

  },
  bundleFour:
  {
    us: {
      id: 4,
      bundleName: null,
      podCount: 0,
      superPodCount: 4,
      imageBaseNameUrl: 'bundle-03-large',
      bagImageBaseNameUrl: 'bundle-checkout-large',
      pricingImageUrl: '/static/images/pricing-images/4-superpods.png',
      pricingImageUrlWebp: '/static/images/pricing-images/4-superpods.webp',
      supportedColors: SUPER_POD_COLORS,
      label: '4 SuperPods',
    },
  },
  bundleNone:
  {
    us: {
      id: 5,
      bundleName: 'None',
      podCount: 0,
      superPodCount: 0,
      imageBaseNameUrl: 'membership-card-app',
      bagImageBaseNameUrl: 'bundle-checkout-photo-membership',
      pricingImageUrl: '',
      supportedColors: [],
      label: 'None',
    },
    uk: {
      id: 5,
      bundleName: 'None',
      podCount: 0,
      superPodCount: 0,
      imageBaseNameUrl: 'membership-card-app',
      bagImageBaseNameUrl: 'bundle-checkout-photo-membership',
      pricingImageUrl: '',
      supportedColors: [],
      label: 'None',
    },
    de: {
      id: 5,
      bundleName: 'None',
      podCount: 0,
      superPodCount: 0,
      imageBaseNameUrl: 'membership-card-app',
      bagImageBaseNameUrl: 'bundle-checkout-photo-membership',
      pricingImageUrl: '',
      supportedColors: [],
      label: 'None',
    },
  },
  bundleTalkTalk:
  {
    us: {
      id: 6,
      bundleName: '----',
      podCount: 0,
      superPodCount: 2,
      imageBaseNameUrl: 'bundle-uk',
      bagImageBaseNameUrl: 'bundle-checkout-uk',
      pricingImageUrl: '',
      supportedColors: UK_COLORS,
      label: '2 SuperPods',
    },
    uk: {
      id: 6,
      bundleName: '----',
      podCount: 0,
      superPodCount: 2,
      imageBaseNameUrl: 'bundle-uk',
      bagImageBaseNameUrl: 'bundle-checkout-uk',
      pricingImageUrl: '',
      supportedColors: UK_COLORS,
      label: '2 SuperPods',
    },
  },
};

export const MEMBERSHIP_TYPES = {
  '710-0031-00': { // US
    label: 'Annual',
    title: 'Annual membership',
    interval: 'Per year',
    renews: 'y',
  },
  '710-0014-00': { // US Lifetime
    label: 'Lifetime',
    title: 'Lifetime membership',
    interval: 'One time only',
    renews: 'o',
  },
  '710-0032-00': { // UK
    label: 'Annual',
    title: 'Annual membership',
    interval: 'Per year',
    renews: 'y',
  },
  '710-0030-00': { // DE
    label: 'Annual',
    title: 'Annual membership',
    interval: 'Per year',
    renews: 'y',
  },
  '710-0054-AN': { // FR
    label: 'Annual',
    title: 'Annual membership',
    interval: 'Per year',
    renews: 'y',
  },
  '710-0029-MT': { // CH sunrise
    label: 'Monthly',
    title: 'Monthly membership',
    interval: 'Per month',
    renews: 'm',
  },
};

export const getBundleType = typeId => BUNDLE_TYPES[typeId];

export const getGalleryImages = (bundle, color) => [
  `/static/images/sku/gallery/${bundle.imageBaseNameUrl}-${color.id}.jpg`,
  `/static/images/sku/gallery/${bundle.imageBaseNameUrl}-${color.id}-02.jpg`,
];

export const extractPriceFromProduct = (product) => {
  if (!product) return 0;
  if (!Array.isArray(product.productRatePlans) || product.productRatePlans.length === 0) return 0;

  const plan = product.productRatePlans[0];
  if (!Array.isArray(plan.productRatePlanCharges)
    || plan.productRatePlanCharges.length === 0) return 0;

  const charge = plan.productRatePlanCharges[0];
  if (!Array.isArray(charge.pricing) || charge.pricing.length === 0) return 0;

  const pricing = charge.pricing[0];

  return pricing.price;
};

export const extractColorFromProduct = (product) => {
  if (!product) return 0;
  if (!Array.isArray(product.productRatePlans) || product.productRatePlans.length === 0) return 0;

  const [plan] = product.productRatePlans;
  return plan.color;
};

export const extractPriceFromMembership = membership => (membership
  && Array.isArray(membership.productRatePlanCharges)
  && membership.productRatePlanCharges.length > 0
  && Array.isArray(membership.productRatePlanCharges[0].pricing)
  ? membership.productRatePlanCharges[0].pricing[0].price : 0);

export const extractSkuFromMembership = membership => (
  membership
    && Array.isArray(membership.productRatePlanCharges)
    && membership.productRatePlanCharges.length > 0
    ? membership.productRatePlanCharges[0].name : ''
);

export const getBundlePackById = (country, size) => BUNDLE_PACKS[country][size];
export const bundleToBundlePackId = (country, bundle) => {
  if (BUNDLE_PACKS[country].small && BUNDLE_PACKS[country].small.bundles.find(b => bundle === b)) return 'small';
  if (BUNDLE_PACKS[country].medium && BUNDLE_PACKS[country].medium.bundles.find(b => bundle === b)) return 'medium';
  if (BUNDLE_PACKS[country].large && BUNDLE_PACKS[country].large.bundles.find(b => bundle === b)) return 'large';
  return null;
};

export const bundleToBundlePack = (country, bundle) => {
  const id = [bundleToBundlePackId(country, bundle)];
  return BUNDLE_PACKS[country][id];
};

export const isBundleInBundlePack = (country, bundle, packId) => {
  const bundlesInPack = BUNDLE_PACKS[country][packId].bundles;
  const foundBundle = bundlesInPack.find(packBundle => packBundle === bundle);
  return !!foundBundle;
};

// Converting links with bundle Ids
export const getBundleSizeString = (id) => {
  switch (id) {
    case '1':
      return 'small';
    case '2':
    case '3':
      return 'medium';
    case '4':
      return 'large';
    case 'small':
    case 'medium':
    case 'large':
      return id;
    default:
      return null;
  }
};

// Converting links with bundle Ids
export const getBundleSizeId = (size) => {
  switch (size) {
    case 'small':
      return 1;
    case 'medium':
      return 2;
    case 'large':
      return 4;
    default:
      return null;
  }
};
