import { types, getRoot } from 'mobx-state-tree';

export const UiStore = types
  .model('UiStore', {
    pageTitle: '',
    activeOverlay: types.optional(types.string, ''),
    activeModal: types.optional(types.string, ''),
    useCookie: types.optional(types.boolean, false),
    isCALocation: types.optional(types.boolean, false),
    isCookieVisible: types.optional(types.boolean, false),
    isCheckoutScrollRequire: types.optional(types.boolean, false),
    isHeaderSticky: types.optional(types.boolean, false),
    isHeaderHidden: types.optional(types.boolean, false),
    // benefits: types.optional(types.boolean, false),
    // TechSpecModalTab: types.optional(types.string, 'smartThingsWifiSpecs'),
    // isPlumeMember: types.optional(types.boolean, false),
    // currencySign: types.maybe(types.string),
    // lastVisitedBundle: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setActiveOverlay(overlayFolderName) {
      self.activeOverlay = overlayFolderName;
    },

    setActiveModal(eligibleComponent) {
      self.activeModal = eligibleComponent;
    },

    setPageTitle(title) {
      self.pageTitle = title;
    },

    setUseCookie(value) {
      self.useCookie = value;
    },

    setCALocation(value) {
      self.isCALocation = value;
    },
    setIsCookieVisibleStatus(status) {
      self.isCookieVisible = status;
    },
    setCheckoutScrollRequireStatus(status) {
      self.isCheckoutScrollRequire = status;
    },
    setIsHeaderStickyStatus(status) {
      self.isHeaderSticky = status;
    },
    setIsHeaderHiddenStatus(status) {
      self.isHeaderHidden = status;
    },
  }))
  .views(self => ({
    getWiFiMotionLink() {
      const { country } = getRoot(self);
      if (country === 'uk') {
        return 'https://www.youtube.com/embed/C89njm5e_X8?autoplay=1&rel=0&modestbranding=1';
      }
      return 'https://www.youtube.com/embed/xYG9O55YC2w?autoplay=1&rel=0&modestbranding=1';
    },
  }));
