import { types } from 'mobx-state-tree';

export default types
  .model('InvoiceAccount', {
    address1: types.optional(types.string, ''),
    address2: types.optional(types.string, ''),
    billingFirstName: types.optional(types.string, ''),
    billingLastName: types.optional(types.string, ''),
    city: types.optional(types.string, ''),
    country: types.optional(types.string, ''),
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    mobilePhone: types.optional(types.string, ''),
    state: types.optional(types.string, ''),
    workEmail: types.optional(types.string, ''),
    workPhone: types.optional(types.string, ''),
    zipCode: types.optional(types.string, ''),
  });
