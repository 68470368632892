// eslint-disable-next-line no-console
import getConfig from 'next/config';

const { publicRuntimeConfig: { ENV } } = getConfig();
console.info('Using environment:', ENV);

const DEVELOPMENT = {
  SENTRY_PUBLIC_DSN: 'https://ff69b9d054f848bf83a22179cb3147d7@sentry.io/1384736',
  SENTRY_ENVIRONMENT: 'development',
  BACKEND_URL: process.browser ? '/api/v2' : 'http://localhost:3000/api/v2',
  API_VERSION: 'v2',
  PUBLIC_IMAGES_FOLDER: '/static/images',
  REACT_APP_ZUORA_HPM_HOST: 'https://apisandboxstatic.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js',
  ZUORA_RENDER_URL: 'https://apisandbox.zuora.com/apps/PublicHostedPageLite.do',
  ZUORA_PAYMENT_GATEWAY: {
    us: 'Stripe Test v2', uk: 'stripe test uk store v2', ukvm3: 'Stripe Test v2', ch: 'Swiss Francs Gateway', be: 'Belgian Euro Stripe Gateway v2', de: 'Belgian Euro Stripe Gateway v2',
  },
  ZUORA_PAYMENT_GATEWAY_LIST: {
    stripe: {
      us: 'Stripe Test v2', uk: 'stripe test uk store v2', ukvm3: 'Stripe Test v2', ch: 'Swiss Francs Gateway', be: 'Belgian Euro Stripe Gateway v2', de: 'Belgian Euro Stripe Gateway v2',
    },
    sepa: {
      be: 'SEPA Strive v2', de: 'SEPA Strive v2',
    },
  },
  REACT_APP_PREFILL_CREDIT_CARD: true,
  REACT_APP_GOOGLE_AUTOCOMPLETE_KEY: 'AIzaSyCTxuYW027PX7z89JzqAiNOYnUeiHNI-Rw',
  GOOGLE_TAG_MANAGER_KEY: 'GTM-M5VB3SK',
  VWO_ACCOUNT_ID: '381447',
  GOOGLE_SITE_VERIFICATION: 'GOOGLE_SITE_VERIFICATION',
  HUBSPOT_SHIPPING_FORM_ID: '4c28b37e-5226-498e-a2cb-f0391e58b37a',
  HUBSPOT_SHIPPING_FORM_ID_UK: '7b3c712e-b5cf-44a3-8119-8b99c3e47633',
  HUBSPOT_BILLING_FORM_ID: 'a38c1a7c-6002-4623-a7f8-437809ab9cf1',
  HUBSPOT_BILLING_FORM_ID_UK: 'bc562815-f594-4a91-9050-70f97651cd2f',
  AFFIRM_URL: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
  AFFIRM_PUBLIC_KEY: 'WS46KNCIDKDCHMXB',
  AFFIRM_SUCCESS_URL: 'http://localhost:3000/store/checkout',
  AFFIRM_CANCEL_URL: 'http://localhost:3000/store/checkout',
  STRIPE_PUBLIC_KEY: 'pk_test_V27iXEGLKXLv81I0GnCRbZCU',
  REFERRAL_URL: 'https://shop.plume.com/homepass/select-hardware?coupon=',
};

const HEROKU_STG = {
  SENTRY_PUBLIC_DSN: 'https://ff69b9d054f848bf83a22179cb3147d7@sentry.io/1384736',
  SENTRY_ENVIRONMENT: 'heroku-stage',
  BACKEND_URL: process.browser ? '/api/v2' : 'https://plume-web-stg-tmp.herokuapp.com/api/v2',
  API_VERSION: 'v2',
  PUBLIC_IMAGES_FOLDER: '/static/images',
  REACT_APP_ZUORA_HPM_HOST: 'https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js',
  ZUORA_RENDER_URL: 'https://www.zuora.com/apps/PublicHostedPageLite.do',
  ZUORA_PAYMENT_GATEWAY: { us: 'Stripe Live', uk: 'Stripe UK Live', ch: 'Swiss Francs Gateway' },
  REACT_APP_PREFILL_CREDIT_CARD: false,
  REACT_APP_GOOGLE_AUTOCOMPLETE_KEY: 'AIzaSyB8jn1h19LPOG_ktFvFfd_jDvqzyCOmIUs',
  GOOGLE_TAG_MANAGER_KEY: 'GTM-M5VB3SK',
  VWO_ACCOUNT_ID: '381447',
  GOOGLE_SITE_VERIFICATION: 'GOOGLE_SITE_VERIFICATION',
  HUBSPOT_SHIPPING_FORM_ID: '4c28b37e-5226-498e-a2cb-f0391e58b37a',
  HUBSPOT_SHIPPING_FORM_ID_UK: '7b3c712e-b5cf-44a3-8119-8b99c3e47633',
  HUBSPOT_BILLING_FORM_ID: 'a38c1a7c-6002-4623-a7f8-437809ab9cf1',
  HUBSPOT_BILLING_FORM_ID_UK: 'bc562815-f594-4a91-9050-70f97651cd2f',
  AFFIRM_URL: 'https://cdn1.affirm.com/js/v2/affirm.js',
  AFFIRM_PUBLIC_KEY: 'V7SH7R1SA6J7B8FQ',
  AFFIRM_SUCCESS_URL: 'https://plume-web-stg-tmp.herokuapp.com/homepass/store/checkout',
  AFFIRM_CANCEL_URL: 'https://plume-web-stg-tmp.herokuapp.com/homepass/store/checkout',
  REFERRAL_URL: 'https://shop.plume.com/homepass/select-hardware?coupon=',
};

const HEROKU_PROD = {
  SENTRY_PUBLIC_DSN: 'https://ff69b9d054f848bf83a22179cb3147d7@sentry.io/1384736',
  SENTRY_ENVIRONMENT: 'heroku-production',
  BACKEND_URL: process.browser ? '/api/v2' : 'https://my.plume.com/api/v2',
  API_VERSION: 'v2',
  PUBLIC_IMAGES_FOLDER: '/static/images',
  REACT_APP_ZUORA_HPM_HOST: 'https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js',
  ZUORA_RENDER_URL: 'https://www.zuora.com/apps/PublicHostedPageLite.do',
  ZUORA_PAYMENT_GATEWAY: {
    us: 'Stripe Live v2', uk: 'Stripe UK Live v2', ukvm3: 'Stripe Live v2', ch: 'Swiss Francs Gateway v2', be: 'Belgian Euro Stripe Gateway v2', de: 'Belgian Euro Stripe Gateway v2',
  },
  ZUORA_PAYMENT_GATEWAY_LIST: {
    stripe: {
      us: 'Stripe Live v2', uk: 'Stripe UK Live v2', ukvm3: 'Stripe Live v2', ch: 'Swiss Francs Gateway v2', be: 'Belgian Euro Stripe Gateway v2', de: 'Belgian Euro Stripe Gateway v2',
    },
    sepa: {
      be: 'SEPA Stripe v2', de: 'SEPA Stripe v2',
    },
  },
  REACT_APP_PREFILL_CREDIT_CARD: true,
  REACT_APP_GOOGLE_AUTOCOMPLETE_KEY: 'AIzaSyB8jn1h19LPOG_ktFvFfd_jDvqzyCOmIUs',
  GOOGLE_TAG_MANAGER_KEY: 'GTM-NNLPSB9',
  VWO_ACCOUNT_ID: '512463',
  GOOGLE_SITE_VERIFICATION: 'T3ETSL-N8xzFvQ0x2nNnG7qbrDy3I-dhbOEMXyyhZzY',
  HUBSPOT_SHIPPING_FORM_ID: '4c28b37e-5226-498e-a2cb-f0391e58b37a',
  HUBSPOT_SHIPPING_FORM_ID_UK: '7b3c712e-b5cf-44a3-8119-8b99c3e47633',
  HUBSPOT_BILLING_FORM_ID: 'a38c1a7c-6002-4623-a7f8-437809ab9cf1',
  HUBSPOT_BILLING_FORM_ID_UK: 'bc562815-f594-4a91-9050-70f97651cd2f',
  AFFIRM_URL: 'https://cdn1.affirm.com/js/v2/affirm.js',
  AFFIRM_PUBLIC_KEY: 'V7SH7R1SA6J7B8FQ',
  AFFIRM_SUCCESS_URL: 'https://www.plume.com/store/checkout',
  AFFIRM_CANCEL_URL: 'https://www.plume.com/store/checkout',
  REFERRAL_URL: 'https://shop.plume.com/homepass/select-hardware?coupon=',
};

const HEROKU_DEV = {
  SENTRY_PUBLIC_DSN: 'https://ff69b9d054f848bf83a22179cb3147d7@sentry.io/1384736',
  SENTRY_ENVIRONMENT: 'heroku-dev',
  BACKEND_URL: process.browser ? '/api/v2' : 'https://plume-web-dev-dashboard.herokuapp.com/api/v2',
  API_VERSION: 'v2',
  PUBLIC_IMAGES_FOLDER: '/static/images',
  REACT_APP_ZUORA_HPM_HOST: 'https://apisandboxstatic.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js',
  ZUORA_RENDER_URL: 'https://apisandbox.zuora.com/apps/PublicHostedPageLite.do',
  ZUORA_PAYMENT_GATEWAY: {
    us: 'Stripe Test v2', uk: 'stripe test uk store v2', ukvm3: 'Stripe Test v2', ch: 'Swiss Francs Gateway', be: 'Belgian Euro Stripe Gateway v2', de: 'Belgian Euro Stripe Gateway v2',
  },
  ZUORA_PAYMENT_GATEWAY_LIST: {
    stripe: {
      us: 'Stripe Test v2', uk: 'stripe test uk store v2', ukvm3: 'Stripe Test v2', ch: 'Swiss Francs Gateway', be: 'Belgian Euro Stripe Gateway v2', de: 'Belgian Euro Stripe Gateway v2',
    },
    sepa: {
      be: 'SEPA Strive v2',
    },
  },
  REACT_APP_PREFILL_CREDIT_CARD: false,
  REACT_APP_GOOGLE_AUTOCOMPLETE_KEY: 'AIzaSyBYD0WIeLwrOXOX328ZrH4JoJneY2HvQIo',
  GOOGLE_TAG_MANAGER_KEY: 'GTM-M5VB3SK',
  VWO_ACCOUNT_ID: '381447',
  GOOGLE_SITE_VERIFICATION: 'GOOGLE_SITE_VERIFICATION',
  HUBSPOT_SHIPPING_FORM_ID: '4c28b37e-5226-498e-a2cb-f0391e58b37a',
  HUBSPOT_SHIPPING_FORM_ID_UK: '7b3c712e-b5cf-44a3-8119-8b99c3e47633',
  HUBSPOT_BILLING_FORM_ID: 'a38c1a7c-6002-4623-a7f8-437809ab9cf1',
  HUBSPOT_BILLING_FORM_ID_UK: 'bc562815-f594-4a91-9050-70f97651cd2f',
  AFFIRM_URL: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
  AFFIRM_PUBLIC_KEY: 'WS46KNCIDKDCHMXB',
  AFFIRM_SUCCESS_URL: 'https://plume-web-dev.herokuapp.com/store/checkout',
  AFFIRM_CANCEL_URL: 'https://plume-web-dev.herokuapp.com/store/checkout',
  STRIPE_PUBLIC_KEY: 'pk_test_V27iXEGLKXLv81I0GnCRbZCU',
  REFERRAL_URL: 'https://shop.plume-develop.com/homepass/select-hardware?coupon=',
};

const HEROKU_DEV_REVIEW = {
  SENTRY_PUBLIC_DSN: 'https://ff69b9d054f848bf83a22179cb3147d7@sentry.io/1384736',
  SENTRY_ENVIRONMENT: 'heroku-dev-review',
  BACKEND_URL: process.browser ? '/api/v2' : `https://${process.env.HEROKU_APP_NAME}.herokuapp.com/api/v2`,
  API_VERSION: 'v2',
  PUBLIC_IMAGES_FOLDER: '/static/images',
  REACT_APP_ZUORA_HPM_HOST: 'https://apisandboxstatic.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js',
  ZUORA_RENDER_URL: 'https://apisandbox.zuora.com/apps/PublicHostedPageLite.do',
  ZUORA_PAYMENT_GATEWAY: { us: 'Stripe Test', uk: 'stripe test uk store', ch: 'Swiss Francs Gateway' },
  REACT_APP_PREFILL_CREDIT_CARD: false,
  REACT_APP_GOOGLE_AUTOCOMPLETE_KEY: 'AIzaSyBYD0WIeLwrOXOX328ZrH4JoJneY2HvQIo',
  GOOGLE_TAG_MANAGER_KEY: 'GTM-M5VB3SK',
  VWO_ACCOUNT_ID: '381447',
  GOOGLE_SITE_VERIFICATION: 'GOOGLE_SITE_VERIFICATION',
  HUBSPOT_SHIPPING_FORM_ID: '4c28b37e-5226-498e-a2cb-f0391e58b37a',
  HUBSPOT_SHIPPING_FORM_ID_UK: '7b3c712e-b5cf-44a3-8119-8b99c3e47633',
  HUBSPOT_BILLING_FORM_ID: 'a38c1a7c-6002-4623-a7f8-437809ab9cf1',
  HUBSPOT_BILLING_FORM_ID_UK: 'bc562815-f594-4a91-9050-70f97651cd2f',
  AFFIRM_URL: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
  AFFIRM_PUBLIC_KEY: 'WS46KNCIDKDCHMXB',
  AFFIRM_SUCCESS_URL: `https://${process.env.HEROKU_APP_NAME}.herokuapp.com/store/checkout`,
  AFFIRM_CANCEL_URL: `https://${process.env.HEROKU_APP_NAME}.herokuapp.com/store/checkout`,
};

let SELECTED = null;

switch (ENV) {
  case 'development':
    SELECTED = DEVELOPMENT;
    break;
  case 'heroku-dev':
    SELECTED = HEROKU_DEV;
    break;
  case 'heroku-dev-review':
    SELECTED = HEROKU_DEV_REVIEW;
    break;
  case 'heroku-stg':
    SELECTED = HEROKU_STG;
    break;
  case 'heroku-prod':
    SELECTED = HEROKU_PROD;
    break;
  default:
    SELECTED = DEVELOPMENT;
    break;
}

SELECTED.COMMIT_HASH = process.env.COMMIT_HASH;

console.warn('Selected environment:', SELECTED);
module.exports = SELECTED;
