export const COUNTRY_US = {
  dateFormat: 'MMM Do',
  value: 'us',
  id: 'country_us',
  alpha3: 'USA',
  label: 'United States',
  currencySign: '$',
  code: 'us',
  currencyCode: 'USD',
};
export const COUNTRY_JP = {
  dateFormat: 'MMM Do',
  value: 'jp',
  id: 'country_jp',
  alpha3: 'JPN',
  label: 'Japan',
  currencySign: '¥',
  code: 'jp',
  currencyCode: '',
};
export const COUNTRY_UK = {
  dateFormat: 'Do MMM',
  value: 'uk',
  id: 'country_uk',
  alpha3: 'GBR',
  label: 'United Kingdom',
  currencySign: '£',
  code: 'uk',
  currencyCode: 'GBP',
};
export const COUNTRY_CA = {
  dateFormat: 'MMM Do',
  value: 'ca',
  id: 'country_ca',
  alpha3: 'CAN',
  label: 'Canada',
  currencySign: '$',
  code: 'ca',
  currencyCode: 'USD',
};
export const COUNTRY_DE = {
  dateFormat: 'MMM Do',
  value: 'de',
  id: 'country_de',
  alpha3: 'DEU',
  label: 'Germany',
  currencySign: '€',
  code: 'de',
  currencyCode: 'EUR',
};
export const COUNTRY_BE = {
  dateFormat: 'MMM Do',
  value: 'be',
  id: 'country_be',
  alpha3: 'BEL',
  label: 'Belgium',
  currencySign: '€',
  code: 'be',
  currencyCode: 'EUR',
};
export const COUNTRY_CY = {
  dateFormat: 'MMM Do',
  value: 'cy',
  id: 'country_cy',
  alpha3: 'CYP',
  label: 'Cyprus',
  currencySign: '€',
  code: 'cy',
  currencyCode: 'EUR',
};
export const COUNTRY_CH = {
  dateFormat: 'MMM Do',
  value: 'ch',
  id: 'country_ch',
  alpha3: 'CHE',
  label: 'Switzerland',
  currencySign: 'CHF',
  code: 'ch',
  currencyCode: 'CHF',
};
export const COUNTRY_DEFAULT = COUNTRY_US;

export const supportedCountries = [
  COUNTRY_US,
  COUNTRY_JP,
  COUNTRY_UK,
  COUNTRY_CA,
  COUNTRY_DE,
  COUNTRY_CH,
  COUNTRY_BE,
  COUNTRY_CY,
];

export const CANADA_PROVINCES = [
  { name: 'Ontario', abbreviation: 'ON' },
  { name: 'Quebec', abbreviation: 'QC' },
  { name: 'Nova Scotia', abbreviation: 'NS' },
  { name: 'New Brunswick', abbreviation: 'NB' },
  { name: 'Manitoba', abbreviation: 'MB' },
  { name: 'British Columbia', abbreviation: 'BC' },
  { name: 'Prince Edward Island', abbreviation: 'PE' },
  { name: 'Saskatchewan', abbreviation: 'SK' },
  { name: 'Alberta', abbreviation: 'AB' },
  { name: 'Newfoundland and Labrador', abbreviation: 'NL' },
  { name: 'Northwest Territories', abbreviation: 'NT' },
  { name: 'Yukon', abbreviation: 'YT' },
  { name: 'Nunavut', abbreviation: 'NU' },
];

export const US_MILITARY_STATES = ['AA', 'AE', 'AP'];

/**
 *
 * @type {object.<string, string>}
 */
export const Countries = {
  UK: 'uk',
  US: 'us',
  CA: 'ca',
  FR: 'fr',
  DE: 'de',
  CY: 'cy',
};

export const CountryCurrency = {
  USD: COUNTRY_US.code,
  GBP: COUNTRY_UK.code,
  CHF: COUNTRY_CH.code,
  EUR: COUNTRY_BE.code,
};

/* Helper function for supported countries searching */
const compareLabelLowerCase = original => c => c.label.toLowerCase() === original.toLowerCase();

/**
 * Returns all country details by country label
 * @param {string} countryLabel
 */
export const GetCountryDetailsByLabel = (countryLabel) => {
  const details = supportedCountries.find(compareLabelLowerCase(countryLabel));
  return details;
};

/**
 * Return currency sign for selected country
 * @param country
 */
export const getCurrencySign = (country) => {
  const result = supportedCountries.find(compareLabelLowerCase(country));
  return result ? result.currencySign : '';
};

/**
 * Return currency code for selected country
 * @param country
 */
export const getCurrencyCode = (country) => {
  const result = supportedCountries.find(compareLabelLowerCase(country));
  return result ? result.currencyCode : '';
};

/**
 * Gets country code from Country string. If Countri is not supported it will return `NotSupported`
 * @param {string} country
 */
export const getCountryCode = (country) => {
  const result = supportedCountries.find(compareLabelLowerCase(country));
  return result ? result.value : 'NotSupported';
};

/**
 * Gets country code from Country Value string
 * @param {string} country
 * @returns {Country}
 */
export const getCountryByValue = (countryValue = '') => supportedCountries.find(c => c.value === countryValue.toLowerCase());

/**
 * Returns dateFormat by country code
 * @param {string} countryCode
 */
export const getCountryDateFormat = (countryCode) => {
  const result = supportedCountries.find(compareLabelLowerCase(countryCode));
  return result ? result.dateFormat : COUNTRY_DEFAULT.dateFormat;
};

/**
 * @typedef Country
 * @property {String} value
 * @property {String} id
 * @property {String} label
 * @property {String} currencySign
 * @property {String} code
 * @property {String} currencyCode
 */
