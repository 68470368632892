import { types } from 'mobx-state-tree';

export default types
  .model('AccountTaxInfo', {
    VATId: types.maybeNull(types.string),
    companyCode: types.maybeNull(types.string),
    exemptCertificateId: types.maybeNull(types.string),
    exemptCertificateType: types.maybeNull(types.string),
    exemptDescription: types.maybeNull(types.string),
    exemptEffectiveDate: types.maybeNull(types.string),
    exemptEntityUseCode: types.maybeNull(types.string),
    exemptExpirationDate: types.maybeNull(types.string),
    exemptIssuingJurisdiction: types.maybeNull(types.string),
    exemptStatus: types.maybeNull(types.string),
  });
