import React, { Component } from 'react';

export default class IconClose extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="26px"
        height="26px"
        viewBox="0 0 26 26"
        className={this.props.className}
      >
        <path
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
          strokeWidth="1.5px"
          stroke={this.props.strokeColor ? this.props.strokeColor : undefined}
          transform="translate(1.000000, 1.000000)"
          d="M12,12 L0.889,23.111 L12,12 L0.444,0.444 L12,12 Z M12,12 L23.111,0.889 L12,12 L23.556,23.556 L12,12 Z"
        />
      </svg>
    );
  }
}
