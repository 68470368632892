import axios from 'axios';
import { BACKEND_URL } from 'env';

export const postMagicLink = async (email, redirectUrl) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/auth/login`,
      responseType: 'json',
      data: {
        email,
        redirectUrl,
      },
    });
    return response.data;
  } catch (err) {
    return err.response;
  }
};
