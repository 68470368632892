import { types, getRoot } from 'mobx-state-tree';
import { getEstimatedArrival } from 'api/checkout';
import ProductRatePlanCharge from './ProductRatePlanCharge';

export default types
  .model('ShippingFee', {
    description: types.optional(types.string, ''),
    id: types.optional(types.string, ''),
    metaMethod: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    productRatePlanCharges: types.optional(types.array(ProductRatePlanCharge), []),
    status: types.optional(types.string, ''),
    estimatedDelivery: types.optional(types.string, ''),
    loadingEstimatedArrival: types.optional(types.boolean, false),
  }).views(self => ({
    get price() {
      return self.productRatePlanCharges[0].pricing[0].price;
    },
    get label() {
      return self.metaMethod.charAt(0).toUpperCase() + self.metaMethod.slice(1);
    },
    get arrivalDate() {
      return self.metaMethod.charAt(0).toUpperCase() + self.metaMethod.slice(1);
    },
  }))
  .actions(self => ({
    async getEstimatedArrival() {
      const {
        country,
      } = getRoot(self);
      self.loadingEstimatedArrival = true;
      const delayedShippingDate = '';
      try {
        const response = await getEstimatedArrival(delayedShippingDate,
          self.metaMethod,
          country);
        self.getEstimatedArrivalSuccess(response);
      } catch (error) {
        self.getEstimatedArrivalError(error);
      }
    },
    getEstimatedArrivalSuccess(data) {
      self.estimatedDelivery = data.estimatedArrival;
      self.loadingEstimatedArrival = false;
    },

    getEstimatedArrivalError(error) {
      console.error(error);
      self.loadingEstimatedArrival = false;
    },
  }));
