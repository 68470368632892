import moment from 'moment';
import { types } from 'mobx-state-tree';
import { POD_COLORS, extractPriceFromProduct, extractColorFromProduct } from 'utils/bundles';
import { getProductType, getGalleryImages } from 'utils/products';
import ProductRatePlan from './ProductRatePlan';

// TODO: find a better place for these enums
const DEFAULT_SHIPPING_DATE = '';
const BACKORDERED = 'backordered';

export default types
  .model('Pod', {
    description: types.string,
    id: types.string,
    typeId: types.optional(types.string, ''),
    name: types.string,
    quantity: types.optional(types.integer, 0),
    productRatePlans: types.optional(types.array(ProductRatePlan), []),
    sku: types.string,

  }).views(self => ({
    get imageUrl() {
      return getProductType(self.typeId).imageUrl;
    },
    get speed() {
      return getProductType(self.typeId).speed;
    },
    get productName() {
      return getProductType(self.typeId).name;
    },
    get labelDescription() {
      return getProductType(self.typeId).description;
    },
    get price() {
      return extractPriceFromProduct(self);
    },
    get color() {
      return extractColorFromProduct(self);
    },
    get supportedColors() {
      const supportedColors = [];
      self.productRatePlans.forEach((rp) => {
        const color = POD_COLORS[rp.color.toUpperCase()];
        supportedColors.push(color);
      });
      return supportedColors;
    },
  })).actions(self => ({
    galleryImages(color) {
      return getGalleryImages(getBundleType(self.typeId), color);
    },
    getNameForQuantity(qty) {
      const typeData = getProductType(self.typeId);
      if (qty > 1) {
        return typeData.qtyName.plural;
      }
      return typeData.qtyName.singular;
    },
    isBackordered(color) {
      const ratePlan = self.productRatePlans.find(rp => rp.color === color);
      if (ratePlan && ratePlan.productRatePlanCharges[0]) {
        const stockStatus = ratePlan.productRatePlanCharges[0].StockStatus__c;

        if (stockStatus && stockStatus.toLowerCase().includes(BACKORDERED)) {
          return true;
        }
      }
      return false;
    },
    isColorAvailable(color) {
      const ratePlan = self.productRatePlans.find(rp => rp.color === color);
      return !!ratePlan;
    },
    shippingDate(color) {
      const ratePlan = self.productRatePlans.find(rp => rp.color === color);

      if (ratePlan && ratePlan.productRatePlanCharges[0]) {
        const inStock = ratePlan.productRatePlanCharges[0].InStock__c;
        const stockStatus = ratePlan.productRatePlanCharges[0].StockStatus__c;

        if (stockStatus && stockStatus.toLowerCase().includes(BACKORDERED) && inStock) {
          return `Backordered until ${moment(inStock).format('MMMM D, YYYY')}`;
        }

        if (stockStatus && stockStatus.toLowerCase().includes(BACKORDERED)) {
          return 'Backordered';
        }

        if (stockStatus && stockStatus.toLowerCase().includes('delayed_shipment') && inStock) {
          return `Ships ${moment(inStock).format('MMM D')}`;
        }

        return stockStatus || DEFAULT_SHIPPING_DATE;
      }
      return DEFAULT_SHIPPING_DATE;
    },
  }));
