import { types } from 'mobx-state-tree';
import { extractPriceFromMembership, extractSkuFromMembership, MEMBERSHIP_TYPES } from 'utils/bundles';
import ProductRatePlanCharge from './ProductRatePlanCharge';

export default types
  .model('Membership', {
    description: types.optional(types.string, ''),
    id: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    productRatePlanCharges: types.optional(types.array(ProductRatePlanCharge), []),
    status: types.optional(types.string, ''),
    type: types.optional(types.string, ''),
  }).views(self => ({
    get price() {
      return extractPriceFromMembership(self);
    },
    get sku() {
      return extractSkuFromMembership(self);
    },
    get label() {
      return MEMBERSHIP_TYPES[self.sku].label;
    },
    get displayPrice() {
      return MEMBERSHIP_TYPES[self.sku].displayPrice || self.price;
    },
    get interval() {
      return MEMBERSHIP_TYPES[self.sku].interval;
    },
    get renews() {
      return MEMBERSHIP_TYPES[self.sku].renews;
    },
    get title() {
      return MEMBERSHIP_TYPES[self.sku].title;
    },
  }));
