import { types, getRoot } from 'mobx-state-tree';
import moment from 'moment';
import { POD_COLORS, BUNDLE_TYPE_INDEX, getBundleType } from 'utils/bundles';
import {
  AFFIRM_SUCCESS_URL,
  AFFIRM_CANCEL_URL,
} from 'env';
import {
  postCouponCode,
  deleteCouponCode,
  getPaymentInfo,
  completePayment,
  fetchTaxes,
} from 'api/checkout';
import { saveToServerSession } from 'api/session';
import {
  PaymentTypes,
} from 'constants/payment';
import { optionsWithAffirm, optionsWithoutAffirm } from 'constants/checkoutOptions';
import CouponData from './models/CouponData';
import { deleteCookie } from '../utils/Cookies';

const Taxes = types.model({
  totalContractedValue: types.maybeNull(types.number),
  amount: types.optional(types.number, 0),
  amountWithoutTax: types.optional(types.number, 0),
  taxAmount: types.optional(types.number, 0),
});

export const CheckoutStore = types
  .model('CheckoutStore', {
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    telephone: types.optional(types.string, ''),
    address: types.optional(types.string, ''),
    apartment: types.optional(types.string, ''),
    city: types.optional(types.string, ''),
    state: types.optional(types.string, ''),
    country: types.optional(types.string, ''),
    postalCode: types.optional(types.string, ''),
    couponStatus: types.optional(types.string, ''),
    couponErrorText: types.optional(types.string, ''),
    couponValue: types.optional(types.string, ''),
    isPoBox: types.optional(types.boolean, false),
    isReceiveInformation: types.optional(types.boolean, false),
    isUseReferralCredits: types.optional(types.boolean, true),
    autoRenewMembership: types.optional(types.boolean, false),
    // availableColors: types.optional(types.array(types.string), []),
    billingAddress: types.optional(types.string, ''),
    billingApartment: types.optional(types.string, ''),
    billingCity: types.optional(types.string, ''),
    billingState: types.optional(types.string, ''),
    billingCountryRegion: types.optional(types.string, ''),
    billingFirstName: types.optional(types.string, ''),
    billingLastName: types.optional(types.string, ''),
    isBillingPoBox: types.optional(types.boolean, false),
    billingPostalCode: types.optional(types.string, ''),
    isBillingAddressSame: types.optional(types.boolean, true),
    cardMaskNumber: types.optional(types.string, ''),
    cardType: types.optional(types.string, ''),
    // computedPrice: types.maybeNull(types.number),
    // countryRegion: types.optional(types.string, ''),
    couponData: types.maybeNull(CouponData),
    lastEnteredCouponData: types.maybeNull(CouponData),
    creditCardExpirationMonth: types.optional(types.string, ''),
    creditCardExpirationYear: types.optional(types.string, ''),
    creditCardHolderName: types.optional(types.string, ''),
    discountCoupon: types.maybeNull(types.string),
    // estimatedDelivery: types.optional(types.string, ''), // Becomes computed value
    // // homeType: null
    // isBillingPostalCodeValid: types.optional(types.boolean, false),
    membershipId: types.optional(types.string, ''),
    nPodsRequested: types.optional(types.number, 0),
    nSuperPodsRequested: types.optional(types.number, 0),
    nPowerPodsRequested: types.optional(types.number, 0),
    nSmartThingsRequested: types.optional(types.number, 0),
    podColor: types.optional(types.string, POD_COLORS.SILVER.id),
    superPodColor: types.optional(types.string, POD_COLORS.SILVER.id),
    powerPodColor: types.optional(types.string, POD_COLORS.SILVER.id),
    // paymentMethod: types.optional(types.string, ''),
    selectedBundle: types.optional(types.string, BUNDLE_TYPE_INDEX.NONE),
    selectedColor: types.optional(types.string, POD_COLORS.SILVER.id),
    shippingDate: types.optional(types.string, ''),
    // NOTE: This next prop should be named same as ShippingFee since it is the same
    shippingMethod: types.optional(types.string, ''),
    taxes: types.optional(Taxes, {}),
    savingToServerSession: types.optional(types.boolean, false),
    completingPayment: types.optional(types.boolean, false),
    loadingSession: types.optional(types.boolean, false),
    paymentInfoLoading: types.optional(types.boolean, false),
    loadingEstimatedArrival: types.optional(types.boolean, false),
    taxStatus: types.optional(types.string, ''),
    paymentMethodStatus: types.optional(types.string, ''),
    loadingCreditCardStatus: types.optional(types.boolean, false),
    paymentOptionId: types.optional(types.string, 'credit-card'),
    defaultPaymentMethodId: types.optional(types.string, ''),
    defaultPaymentMethodCvv: types.optional(types.string, ''),
    isSetNewCardAsDefault: types.optional(types.boolean, false),
  })
  .views(self => ({
    get total() {
      const { country } = getRoot(self);

      let total = self.subtotal;
      total -= self.totalDiscount;

      total -= self.usedCreditBalance;

      if (country === 'us') {
        // Add tax to the value
        total += self.tax;
      }
      // Add shipping that is neither taxed nor discounted
      total += self.shippingWithoutDiscount;

      return Math.abs(total);
    },
    get computedPriceTotal() {
      const { country } = getRoot(self);

      let total = self.subtotal;

      total -= self.discount;

      total -= self.usedCreditBalance;

      if (country === 'us') {
        // Add tax to the value
        total += self.tax;
      }
      // Add shipping that is neither taxed nor discounted
      total += self.shippingWithoutDiscount;
      // total += self.shipping;

      return Math.abs(total);
    },
    get creditBalance() {
      const { localAccount } = getRoot(self).accountStore;
      let creditBalance = 0;
      if (localAccount && localAccount.accountData && localAccount.accountData.metrics) {
        // eslint-disable-next-line prefer-destructuring
        creditBalance = localAccount.accountData.metrics.creditBalance;
      }
      return creditBalance;
    },
    get usedCreditBalance() {
      if (!self.isUseReferralCredits) {
        return 0;
      }
      return Math.min(
        self.creditBalance,
        self.subtotal
        - self.discount
        + self.tax
        + self.shippingWithoutDiscount,
      );
    },
    get isAffirmOptionVisible() {
      return getRoot(self).country === 'us' && ((self.subtotal) >= 50);
    },
    get isAffirmOptionActive() {
      return getRoot(self).country === 'us' && self.total >= 50;
    },
    get storeAffirmCents() {
      if (!Number.isNaN(self.subtotal)) {
        return (self.subtotal) * 100;
      }
      return 0;
    },
    get storeAffirmPaymentTerm() {
      const minTotalPrice = 5100;
      const maxTotalPrice = 9900;
      const minPaymentTerm = 6;
      const maxPaymentTerm = 12;
      if (self.storeAffirmCents >= minTotalPrice && self.storeAffirmCents <= maxTotalPrice) {
        return minPaymentTerm;
      }
      return maxPaymentTerm;
    },
    get cents() {
      if (!Number.isNaN(self.total)) {
        return self.total * 100;
      }
      return 0;
    },
    get affirmPaymentTerm() {
      const minTotalPrice = 5100;
      const maxTotalPrice = 9900;
      const minPaymentTerm = 6;
      const maxPaymentTerm = 12;
      if (self.cents >= minTotalPrice && self.cents <= maxTotalPrice) {
        return minPaymentTerm;
      }
      return maxPaymentTerm;
    },
    get subtotal() {
      let subtotal = 0;

      const products = getRoot(self).productsStore.localProducts;

      if (!products) {
        return subtotal;
      }

      if (self.membershipId !== '') {
        const membership = products.memberships.find(m => m.id === self.membershipId);
        if (membership) {
          subtotal += membership.price;
        }
      }

      if (self.selectedBundle && self.selectedBundle !== 'bundleNone') {
        const bundle = products[self.selectedBundle];
        if (bundle) {
          subtotal += bundle.toObject.totalPrice;
        }
      }

      if (self.nPodsRequested && products.pod) {
        subtotal += products.pod.price * self.nPodsRequested;
      }

      if (self.nSuperPodsRequested && products.superPod) {
        subtotal += products.superPod.price * self.nSuperPodsRequested;
      }

      if (self.nPowerPodsRequested && products.powerPod) {
        subtotal += products.powerPod.price * self.nPowerPodsRequested;
      }

      if (self.nSmartThingsRequested && products.smartThings) {
        subtotal += products.smartThings.price * self.nSmartThingsRequested;
      }

      return subtotal;
    },
    get tax() {
      return self.taxes.taxAmount;
    },
    get estimatedDelivery() {
      const products = getRoot(self).productsStore.localProducts;

      const shippingFee = products.shippingFees.find(
        sm => sm.metaMethod === self.shippingMethod,
      );

      if (shippingFee) {
        return shippingFee.estimatedDelivery;
      }

      return '';
    },
    get shippingWithoutDiscount() {
      const products = getRoot(self).productsStore.localProducts;
      let method = self.shippingMethod;
      if (method === '') {
        method = products.shippingFees[0].metaMethod;
      }

      let shipping = products.shippingFees.find(sf => sf.metaMethod === method);
      if (!shipping) [shipping] = products.shippingFees;
      return shipping.price;
    },
    get shipping() {
      const products = getRoot(self).productsStore.localProducts;
      let method = self.shippingMethod;
      if (method === '') {
        method = products.shippingFees[0].metaMethod;
      }

      let shipping = products.shippingFees.find(sf => sf.metaMethod === method);
      if (!shipping) [shipping] = products.shippingFees;
      if (self.multiplier) {
        return shipping.price - (shipping.price * self.multiplier);
      }
      return shipping.price;
    },
    get membership() {
      if (!self.membershipId) {
        return null;
      }
      const products = getRoot(self).productsStore.localProducts;
      if (products) {
        return products.memberships.find(m => m.id
          === self.membershipId);
      }
      return null;
    },
    get quarantineDiscount() {
      const coupon = self.couponData;
      if (coupon && coupon.discount.ratePlan.productRatePlanCharges[0].name.includes('710-DISC-001')) return 0;
      let discount = 0;
      // Quarantine
      if ((['us', 'uk'].includes(getRoot(self).country)) && self.membershipId !== '') {
        discount += 50;
      }
      if ((['ch'].includes(getRoot(self).country)) && self.membershipId !== '') {
        discount += 4.9;
      }
      return discount;
    },
    get totalQuarantineDiscount() {
      let discount = 0;
      if ((['us', 'uk'].includes(getRoot(self).country)) && self.membershipId !== '') {
        if (self.membership && self.multiplier >= 0.5) {
          discount = self.membership.price - (self.membership.price * self.multiplier);
          return discount;
        }
        discount += 50;
      }
      if ((['ch'].includes(getRoot(self).country)) && self.membershipId !== '') {
        discount += 4.9;
      }
      return discount;
    },
    get isCorporateDiscount() {
      const coupon = self.couponData;
      if (coupon && coupon.discount.ratePlan.productRatePlanCharges[0].name.includes('710-DISC-001')) return true;
      return false;
    },
    get discount() {
      let discount = 0;
      const coupon = self.couponData;
      const userReferralCredits = self.creditBalance;
      if (userReferralCredits) {
        // discount += userReferralCredits;
      }

      if (!coupon) return discount;

      const products = getRoot(self).productsStore.localProducts;
      if (self.membershipId !== '') {
        const membership = products.memberships.find(m => m.id === self.membershipId);
        const multiplier = coupon.getDiscountMultiplier(
          self.currency,
          membership.productRatePlanCharges[0].id,
        );
        discount += membership.price * multiplier;
      }

      let method = self.shippingMethod;
      if (method === '') {
        method = products.shippingFees[0].metaMethod;
      }

      let shipping = products.shippingFees.find(sf => sf.metaMethod === method);
      if (!shipping) [shipping] = products.shippingFees;
      if (self.multiplier) {
        discount += shipping.price * self.multiplier;
      }

      if (self.selectedBundle && self.selectedBundle !== 'bundleNone') {
        const bundle = products[self.selectedBundle].toObject;
        const multiplier = coupon.getDiscountMultiplier(
          self.currency,
          products[self.selectedBundle].productRatePlans[0].productRatePlanCharges[0].id,
        );
        discount += bundle.totalPrice * multiplier;
      }

      if (self.nPodsRequested) {
        const multiplier = coupon.getDiscountMultiplier(
          self.currency,
          products.pod.productRatePlans[0].productRatePlanCharges[0].id,
        );
        discount += products.pod.price * self.nPodsRequested * multiplier;
      }

      if (self.nPowerPodsRequested) {
        const multiplier = coupon.getDiscountMultiplier(
          self.currency,
          products.powerPod.productRatePlans[0].productRatePlanCharges[0].id,
        );
        discount += products.powerPod.price * self.nPowerPodsRequested * multiplier;
      }

      if (self.nSuperPodsRequested) {
        const multiplier = coupon.getDiscountMultiplier(
          self.currency,
          products.superPod.productRatePlans[0].productRatePlanCharges[0].id,
        );
        discount += products.superPod.price * self.nSuperPodsRequested * multiplier;
      }

      if (coupon.campaign.includes('Free SP') && !self.nSuperPodsRequested && self.nPowerPodsRequested) {
        return discount;
      }

      const discountAmount = coupon.getFixedDiscountAmount(self.currency);
      discount = Number.isNaN(discount) ? discountAmount : discount + discountAmount;
      discount = Math.min(discount, self.subtotal);
      return discount;
    },
    get totalDiscount() {
      let discount = 0;
      const coupon = self.couponData;
      const userReferralCredits = self.creditBalance;
      if (userReferralCredits) {
        // discount += userReferralCredits;
      }

      if (!coupon) return discount;

      const products = getRoot(self).productsStore.localProducts;
      if (self.membershipId !== '') {
        const membership = products.memberships.find(m => m.id === self.membershipId);
        const multiplier = coupon.getDiscountMultiplier(
          self.currency,
          membership.productRatePlanCharges[0].id,
        );
        discount += membership.price * multiplier;
      }
      let method = self.shippingMethod;
      if (method === '') {
        method = products.shippingFees[0].metaMethod;
      }

      let shipping = products.shippingFees.find(sf => sf.metaMethod === method);
      if (!shipping) [shipping] = products.shippingFees;
      if (self.multiplier) {
        discount += shipping.price * self.multiplier;
      }

      if (self.selectedBundle && self.selectedBundle !== 'bundleNone') {
        const bundle = products[self.selectedBundle].toObject;
        const multiplier = coupon.getDiscountMultiplier(
          self.currency,
          products[self.selectedBundle].productRatePlans[0].productRatePlanCharges[0].id,
        );
        discount += bundle.totalPrice * multiplier;
      }

      if (self.nPodsRequested) {
        const multiplier = coupon.getDiscountMultiplier(
          self.currency,
          products.pod.productRatePlans[0].productRatePlanCharges[0].id,
        );
        discount += products.pod.price * self.nPodsRequested * multiplier;
      }

      if (self.nPowerPodsRequested) {
        const multiplier = coupon.getDiscountMultiplier(
          self.currency,
          products.powerPod.productRatePlans[0].productRatePlanCharges[0].id,
        );
        discount += products.powerPod.price * self.nPowerPodsRequested * multiplier;
      }

      if (self.nSuperPodsRequested) {
        const multiplier = coupon.getDiscountMultiplier(
          self.currency,
          products.superPod.productRatePlans[0].productRatePlanCharges[0].id,
        );
        discount += products.superPod.price * self.nSuperPodsRequested * multiplier;
      }
      if (coupon.campaign.includes('Free SP') && !self.nSuperPodsRequested && self.nPowerPodsRequested) {
        return discount;
      }
      const discountAmount = coupon.getFixedDiscountAmount(self.currency);
      discount = Number.isNaN(discount) ? discountAmount : discount + discountAmount;
      // discount = Math.min(discount, self.subtotal);
      return discount;
    },
    get multiplier() {
      const multiplier = 0;
      const coupon = self.couponData;

      if (!coupon) return multiplier;

      const products = getRoot(self).productsStore.localProducts;
      if (self.membershipId !== '') {
        const membership = products.memberships.find(m => m.id === self.membershipId);
        return coupon.getDiscountMultiplier(
          self.currency,
          membership.productRatePlanCharges[0].id,
        );
      }

      if (self.selectedBundle && self.selectedBundle !== 'bundleNone') {
        return coupon.getDiscountMultiplier(
          self.currency,
          products[self.selectedBundle].productRatePlans[0].productRatePlanCharges[0].id,
        );
      }

      if (self.nPodsRequested) {
        return coupon.getDiscountMultiplier(
          self.currency,
          products.pod.productRatePlans[0].productRatePlanCharges[0].id,
        );
      }

      if (self.nPowerPodsRequested) {
        return coupon.getDiscountMultiplier(
          self.currency,
          products.powerPod.productRatePlans[0].productRatePlanCharges[0].id,
        );
      }

      if (self.nSuperPodsRequested) {
        return coupon.getDiscountMultiplier(
          self.currency,
          products.superPod.productRatePlans[0].productRatePlanCharges[0].id,
        );
      }
      return multiplier;
    },
    get addOns() {
      return [
        {
          id: 'pod',
          qty: self.nPodsRequested,
          label: self.nPodsRequested > 1 ? 'Pods' : 'Pod',
        },
        {
          id: 'superPod',
          qty: self.nSuperPodsRequested,
          label: self.nSuperPodsRequested > 1 ? 'SuperPods' : 'SuperPod',
        },
        {
          id: 'powerPod',
          qty: self.nPowerPodsRequested,
          label: self.nPowerPodsRequested > 1 ? 'PowerPods' : 'PowerPod',
        },
        {
          id: 'smartThings',
          qty: self.nSmartThingsRequested,
          label: self.nSmartThingsRequested > 1 ? 'SmartThings Wifi' : 'SmartThings Wifi',
        },
      ];
    },
    get currencySign() {
      if (getRoot(self).country === 'us') {
        return '$';
      }
      if (getRoot(self).country === 'uk') {
        return '£';
      }
      if (getRoot(self).country === 'de') {
        return '€';
      }
      if (getRoot(self).country === 'be') {
        return '€';
      }
      if (getRoot(self).country === 'cy') {
        return '€';
      }
      if (getRoot(self).country === 'ch') {
        return 'CHF';
      }
      return 'Error - miconfigured country';
    },
    get currency() {
      if (getRoot(self).country === 'us') {
        return 'USD';
      }
      if (getRoot(self).country === 'uk') {
        return 'GBP';
      }
      if (getRoot(self).country === 'de') {
        return 'EUR';
      }
      if (getRoot(self).country === 'be') {
        return 'EUR';
      }
      if (getRoot(self).country === 'cy') {
        return 'EUR';
      }
      if (getRoot(self).country === 'ch') {
        return 'CHF';
      }
      return '';
    },
    get podsColorValid() {
      const products = getRoot(self).productsStore.localProducts;

      if (self.nPodsRequested > 0 && products.pod.isBackordered(self.podColor)) {
        return false;
      }

      if (self.nSuperPodsRequested > 0 && products.superPod.isBackordered(self.superPodColor)) {
        return false;
      }

      if (self.nPowerPodsRequested > 0 && products.powerPod.isBackordered(self.powerPodColor)) {
        return false;
      }

      return true;
    },
    get bundleValid() {
      const products = getRoot(self).productsStore.localProducts;
      const bundle = products[self.selectedBundle];

      if (self.selectedBundle && self.selectedBundle !== 'bundleNone') {
        if (bundle.isBackordered(self.selectedColor)) {
          return false;
        }
      }

      return true;
    },
    get hasShippableProducts() {
      if (self.selectedBundle && self.selectedBundle !== 'bundleNone') {
        return true;
      }

      if (self.nPodsRequested > 0) {
        return true;
      }

      if (self.nSuperPodsRequested > 0) {
        return true;
      }

      if (self.nPowerPodsRequested > 0) {
        return true;
      }
      return false;
    },
    get cartItems() {
      const items = {};
      const products = getRoot(self).productsStore.localProducts;
      if (products) {
        items.membership = products.memberships.find(m => m.id === self.membershipId);
        items.bundle = products[self.selectedBundle];

        items.pods = {
          pod: products.pod,
          qty: self.nPodsRequested,
        };

        items.superPods = {
          pod: products.superPod,
          qty: self.nSuperPodsRequested,
        };

        items.powerPods = {
          pod: products.powerPod,
          qty: self.nPowerPodsRequested,
        };

        items.smartThings = {
          pod: products.smartThings,
          qty: self.nSmartThingsRequested,
        };
      }
      return items;
    },
    get affirmCartItems() {
      const items = [];
      const products = getRoot(self).productsStore.localProducts;
      let tempInfo;
      if (products) {
        const membership = products.memberships.find(m => m.id === self.membershipId);
        if (membership) {
          items.push({
            display_name: membership.productRatePlanCharges[0].description,
            sku: membership.productRatePlanCharges[0].name,
            unit_price: membership.price * 100,
            currency: self.currency,
            qty: 1,
          });
        }
        if (products[self.selectedBundle]) {
          tempInfo = products[self.selectedBundle].productRatePlans
            .find(p => p.color === self.selectedColor);
          items.push({
            display_name: tempInfo.productRatePlanCharges[0].description,
            sku: tempInfo.productRatePlanCharges[0].name,
            unit_price: 0,
            currency: self.currency,
            qty: 1,
          });
        }
        if (self.nPodsRequested > 0) {
          tempInfo = products.pod.productRatePlans
            .find(p => p.color === self.selectedColor);
          items.push({
            display_name: tempInfo.productRatePlanCharges[0].description,
            sku: tempInfo.productRatePlanCharges[0].name,
            unit_price: products.pod.price * 100,
            currency: self.currency,
            qty: self.nPodsRequested,
          });
        }
        if (self.nPowerPodsRequested > 0) {
          tempInfo = products.powerPod.productRatePlans
            .find(p => p.color === self.selectedColor);
          items.push({
            display_name: tempInfo.productRatePlanCharges[0].description,
            sku: tempInfo.productRatePlanCharges[0].name,
            unit_price: products.powerPod.price * 100,
            currency: self.currency,
            qty: self.nPowerPodsRequested,
          });
        }
        if (self.nSmartThingsRequested > 0) {
          tempInfo = products.smartThings.productRatePlans
            .find(p => p.color === self.selectedColor);
          items.push({
            display_name: tempInfo.productRatePlanCharges[0].description,
            sku: tempInfo.productRatePlanCharges[0].name,
            unit_price: products.smartThings.price * 100,
            currency: self.currency,
            qty: self.nSmartThingsRequested,
          });
        }
        if (self.nSuperPodsRequested > 0) {
          tempInfo = products.superPod.productRatePlans
            .find(p => p.color === self.selectedColor);
          items.push({
            display_name: tempInfo.productRatePlanCharges[0].description,
            sku: tempInfo.productRatePlanCharges[0].name,
            unit_price: products.superPod.price * 100,
            currency: self.currency,
            qty: self.nSuperPodsRequested,
          });
        }
      }
      return items;
    },
    get cartItemsCount() {
      return Object.values(self.cartItems)
        .reduce((a, c) => (a + ((c && c.qty) ? c.qty : 0)), (self.cartItems.bundle ? 1 : 0));
    },
    get fetchingTaxes() {
      return self.taxStatus === 'fetching';
    },
    get checkoutOptionsList() {
      return self.country === 'us' ? optionsWithAffirm : optionsWithoutAffirm;
    },
    get isCvvValid() {
      return !(self.defaultPaymentMethodCvv === ''
        || self.defaultPaymentMethodCvv.length < 3
        || self.defaultPaymentMethodCvv.length > 4);
    },
    get isCouponCodeValid() {
      if (!self.couponData) {
        return false;
      }
      return self.couponStatus !== 'invalid';
    },
  }))
  .actions(self => ({
    setDefaultMethodId(newId) {
      self.defaultPaymentMethodId = newId;
    },
    setDefaultMethodCVV(newCvv) {
      self.defaultPaymentMethodCvv = newCvv;
    },
    setCardAsDefault(status) {
      self.isSetNewCardAsDefault = status;
    },
    async saveToServerSession() {
      self.savingToServerSession = true;
      try {
        const response = await saveToServerSession(self);
        self.saveToServerSessionSuccess(response);
      } catch (error) {
        self.saveToServerSessionError(error);
      }
    },

    saveToServerSessionSuccess(/* data */) {
      self.savingToServerSession = false;
    },

    saveToServerSessionError(error) {
      console.error(error);
      self.savingToServerSession = false;
    },

    async fetchTaxes(shippingData) {
      self.taxStatus = 'fetching';

      try {
        const response = await fetchTaxes(
          {
            storeCountry: getRoot(self).country,
          },
          self.getStoreState(shippingData),
        );
        self.fetchTaxesSuccess(response);
      } catch (error) {
        self.fetchTaxesError(error);
      }
    },

    setEmptyTaxes() {
      self.taxes = {};
      self.taxStatus = 'success';
      self.saveToServerSession();
    },

    fetchTaxesSuccess(response) {
      self.taxStatus = 'success';
      self.taxes = response.taxes;
      self.saveToServerSession();
    },

    fetchTaxesError() {
      self.taxStatus = 'error';
    },

    setPaymentMethodStatus(status, details = '') {
      self.paymentMethodStatus = status;
      self.paymentMethodStatusDetails = details;
    },

    setPaymentOptionId(id = PaymentTypes.CREDIT_CARD) {
      if (id === PaymentTypes.MONTHLY_PAYMENTS && !self.isAffirmOptionActive) {
        self.paymentOptionId = PaymentTypes.CREDIT_CARD;
        return;
      }
      self.paymentOptionId = id;
    },

    async confirmPaymentMethod() {
      // Z.submit();
      self.setPaymentMethodStatus('loading');
      console.error('Z.submit():', Z.submit());
    },

    async completeDefaultCCardPayment(cb) {
      if (!self.isCvvValid) return;
      self.completingPayment = true;
      self.setPaymentMethodStatus('success');
      const {
        success,
        message,
      } = await getRoot(self).accountStore.localAccount.postPaymentMethodVerify(
        self.defaultPaymentMethodId,
        self.currency,
        self.defaultPaymentMethodCvv,
      );
      if (!success) {
        self.completePaymentError({
          data: {
            message: message.includes('incorrect_cvc') ? 'incorrect-cvv' : message,
          },
        });
        return;
      }
      self.completePayment(self.defaultPaymentMethodId, cb);
    },
    async completePayment(refId, cb) {
      self.completingPayment = true;
      self.setPaymentMethodStatus('success');
      try {
        const response = await completePayment(
          self.paymentOptionId,
          {
            // Add billingAgreementId for PayPal and Amazon here
            storeCountry: getRoot(self).country,
            refId, // We only need this for Zuora credit card payment
          },
          self.getStoreState(),
        );

        if (response.subscriptionId) {
          await getRoot(self).invoiceStore.fetchInvoiceData(response.subscriptionId);
          cb(response);
          self.completePaymentSuccess();
        } else {
          if (response.data) {
            cb(response.data);
          }
          self.completePaymentError(response);
        }
      } catch (error) {
        self.completePaymentError(error);
      }
    },
    setAffirmCheckoutToken(affirmCheckoutToken) {
      self.affirmCheckoutToken = affirmCheckoutToken;
    },
    affirmCheckout() {
      const store = self.getStoreState();
      const shipping = self.getShippingData();
      const discountList = {};
      if (self.couponData !== null) {
        discountList[self.couponData.code] = {
          discount_amount: self.discount * 100,
          discount_display_name: self.couponData.discount.ratePlan.name,
        };
      }
      if (self.usedCreditBalance > 0) {
        discountList['Referral credits'] = {
          discount_amount: self.usedCreditBalance * 100,
          discount_display_name: 'Referral credits',
        };
      }
      return {
        merchant: {
          user_confirmation_url: AFFIRM_SUCCESS_URL,
          user_cancel_url: AFFIRM_CANCEL_URL,
          user_confirmation_url_action: 'POST',
          name: 'PLUME',
        },
        metadata: {
          mode: 'modal',
        },
        shipping: {
          name: {
            first: store.firstName,
            last: store.lastName,
          },
          address: {
            line1: shipping.address1,
            line2: shipping.address2,
            city: shipping.city,
            state: shipping.state,
            zipcode: shipping.postalCode,
            country: store.country,
          },
          phone_number: store.telephone,
          email: store.email,
        },
        billing: {
          name: {
            first: store.firstName,
            last: store.lastName,
          },
          phone_number: store.telephone,
          email: store.email,
        },
        items: self.affirmCartItems,
        shipping_amount: self.shipping * 100,
        tax_amount: self.tax,
        total: Math.round(self.total * 100),
        discounts: discountList,
      }; // easier to attach debugger before return;
    },

    completePaymentSuccess() {
      self.completingPayment = false;
      self.resetStoreData();
    },

    completePaymentError(error) {
      self.setPaymentMethodStatus('error', error.data ? error.data.message : 'Server error');
      self.completingPayment = false;
    },

    async postCouponCode(coupon, cb) {
      const { country } = getRoot(self);
      if (self.couponStatus === 'validating') {
        postCouponCode.source.cancel();
      }
      self.couponStatus = 'validating';
      self.couponValue = coupon;
      self.couponErrorText = 'checkout:billing.errors.invalid-coupon-code';
      self.couponData = null;
      self.lastEnteredCouponData = null;
      const { accessToken } = getRoot(self).authStore;
      try {
        const response = await postCouponCode(coupon, self.getStoreState(), accessToken);
        if (country === 'us' && !response.campaign.includes('Free SP')) {
          self.setCouponText('checkout:billing.errors.coupon-code-invalid');
          throw new Error();
        }
        self.postCouponCodeSuccess(response);
        cb(response.campaign);
      } catch (error) {
        self.postCouponCodeError(error);
        cb();
      }
    },

    setLastEnteredCouponData(couponCode) {
      self.lastEnteredCouponData = couponCode;
    },

    setCouponText(text) {
      self.couponErrorText = text;
    },

    postCouponCodeSuccess(response) {
      if (response.success) {
        const endDate = response.discount && response.discount.ratePlan
          ? response.discount.ratePlan.effectiveEndDate
          : null;
        const startDate = response.discount && response.discount.ratePlan
          ? response.discount.ratePlan.effectiveStartDate
          : null;

        const endDateMoment = endDate ? moment(endDate).endOf('day') : null;
        const startDateMoment = startDate ? moment(startDate).startOf('day') : null;
        const today = moment();
        if ((!endDateMoment || endDateMoment.isAfter(today))
          && (!startDateMoment || startDateMoment.isBefore(today))) {
          self.couponStatus = 'valid';
          self.couponData = response;
        } else {
          self.couponStatus = 'invalid';
        }
      } else {
        if (response.messageError === 'User exist') {
          self.couponErrorText = 'checkout:billing.errors.user-exist-with-coupon-code';
        }
        self.couponStatus = 'invalid';
      }
    },

    postCouponCodeError(error) {
      console.error(error);
      self.couponStatus = 'invalid';
    },

    resetCouponCodeSuccess() {
      self.couponData = null;
    },

    async resetCoupon(cb) {
      try {
        await deleteCouponCode();
        self.resetCouponCodeSuccess();
      } catch (error) {
        // do nothing
      }
      cb();
    },

    resetCouponCodeError() {
      self.couponStatus = '';
    },

    async getPaymentInfo(refId) {
      self.paymentInfoLoading = true;
      try {
        const response = await getPaymentInfo(refId);
        self.getPaymentInfoSuccess(response);
      } catch (error) {
        self.getPaymentInfoError(error);
      }
    },

    getPaymentInfoSuccess(data) {
      self.paymentInfoLoading = false;
      self.creditCardExpirationMonth = `${data.CreditCardExpirationMonth}`;
      self.creditCardExpirationYear = `${data.CreditCardExpirationYear}`;
      self.creditCardHolderName = data.CreditCardHolderName;
      self.cardType = data.CreditCardType;
      self.cardMaskNumber = data.CreditCardMaskNumber;
    },

    getPaymentInfoError(error) {
      console.error(error);
      self.paymentInfoLoading = false;
    },

    setMembershipId(id) {
      self.membershipId = id;
      if (id === '') {
        self.selectedBundle = 'bundleNone';
      }
      self.saveToServerSession();
    },

    setFirstAvailableMembership() {
      const { memberships } = getRoot(self)
        .productsStore.localProducts;
      if (memberships && memberships.length > 0) {
        self.setMembershipId(memberships[0].id);
      } else {
        self.setMembershipId('');
      }
    },

    setSelectedStarterPack(typeId) {
      self.selectedBundle = typeId;
      // change color if not available in new selected bundle
      if (self.selectedBundle && self.selectedBundle !== 'bundleNone') {
        const { supportedColors } = getBundleType(typeId)[getRoot(self).country];
        if (supportedColors.filter(({ id }) => id === self.selectedColor).length === 0) {
          self.selectedColor = 'silver';
        }
      }
      self.saveToServerSession();
    },

    updateAddonsAmount(delta, type) {
      switch (type) {
        case 'pod':
          if (self.nPodsRequested + delta >= 0) {
            self.nPodsRequested += delta;
          }
          break;
        case 'superPod':
          if (self.nSuperPodsRequested + delta >= 0) {
            self.nSuperPodsRequested += delta;
          }
          break;
        case 'powerPod':
          if (self.nPowerPodsRequested + delta >= 0) {
            self.nPowerPodsRequested += delta;
          }
          break;
        default:
          console.error(`Unknown product: ${type}`);
      }
      self.saveToServerSession();
    },

    setAutoRenewMembership(value) {
      self.autoRenewMembership = value;
    },

    setRequestedPodsAmount(amount) {
      self.nPodsRequested = amount;
    },

    setRequestedSuperPodsAmount(amount) {
      self.nSuperPodsRequested = amount;
    },

    setRequestedPowerPodsAmount(amount) {
      self.nPowerPodsRequested = amount;
    },

    setRequestedSmartThingsAmount(amount) {
      self.nSmartThingsRequested = amount;
    },

    resetStoreData() {
      self.setZeroAddonAmount();
      self.billingFirstName = '';
      self.billingLastName = '';
      self.cardMaskNumber = '';
      self.cardType = '';
      self.couponData = null;
      self.creditCardExpirationMonth = '';
      self.creditCardExpirationYear = '';
      self.creditCardHolderName = '';
      self.discountCoupon = '';
      // self.estimatedDelivery = '';
      self.podColor = POD_COLORS.SILVER.id;
      self.superPodColor = POD_COLORS.SILVER.id;
      self.powerPodColor = POD_COLORS.SILVER.id;
      self.selectedColor = POD_COLORS.SILVER.id;
      self.shippingDate = '';
      self.shippingMethod = '';
      self.taxes = {};
      self.savingToServerSession = false;
      self.completingPayment = false;
      self.loadingSession = false;
      self.paymentInfoLoading = false;
      self.loadingEstimatedArrival = false;
      self.taxStatus = '';
      self.couponStatus = '';
      self.couponErrorText = '';
      self.couponValue = '';

      // full clear for not logged in users
      if (getRoot(self).authStore.accessToken === '') {
        self.firstName = '';
        self.lastName = '';
        self.email = '';
        self.telephone = '';
        self.address = '';
        self.apartment = '';
        self.city = '';
        self.state = '';
        self.postalCode = '';

        if (self.membershipId === '') {
          self.setFirstAvailableMembership();
        }

        if (self.selectedBundle !== BUNDLE_TYPE_INDEX.SP) {
          // Bundle is not selected, user pass to this page form other flow, reset addons
          self.setSelectedStarterPack(BUNDLE_TYPE_INDEX.SP);
          self.setZeroAddonAmount();
        }
      }

      deleteCookie('homeConfig');
      self.saveToServerSession();
    },

    setZeroAddonAmount() {
      self.nPodsRequested = 0;
      self.nSuperPodsRequested = 0;
      self.nPowerPodsRequested = 0;
      self.nSmartThingsRequested = 0;
    },

    setSelectedColor(color) {
      const products = getRoot(self).productsStore.localProducts;
      self.selectedColor = color;

      if (products.pod && products.pod.isColorAvailable(color)) {
        self.setPodColor(color);
      }

      if (products.superPod && products.superPod.isColorAvailable(color)) {
        self.setSuperPodColor(color);
      }

      if (products.powerPod && products.powerPod.isColorAvailable(color)) {
        self.setPowerPodColor(color);
      }
    },

    setShippingDate(shippingDate) {
      self.shippingDate = shippingDate;
    },

    setComputedPrice(computedPrice) {
      self.computedPrice = computedPrice;
    },

    setDiscount(discount) {
      self.discountValue = discount;
    },

    setShippingMethod(method) {
      self.shippingMethod = method;
    },

    setIsBillingAddressSame(same) {
      self.isBillingAddressSame = same;
    },

    setPodColor(color) {
      self.podColor = color;
    },

    setSuperPodColor(color) {
      self.superPodColor = color;
    },

    setPowerPodColor(color) {
      self.powerPodColor = color;
    },

    getShippingData() {
      return {
        email: self.email,
        phone: self.telephone,
        firstName: self.firstName,
        lastName: self.lastName,
        address1: self.address,
        address2: self.apartment,
        city: self.city,
        state: self.state,
        postalCode: self.postalCode,
        poBox: self.isPoBox,
        couponCode: (self.couponData && self.couponData.code)
          || (self.lastEnteredCouponData && self.lastEnteredCouponData.code) || '',
        isReceiveInformation: self.isReceiveInformation,
      };
    },

    setShippingData(data) {
      self.email = data.email;
      self.telephone = data.phone || '';
      self.firstName = data.firstName;
      self.lastName = data.lastName;
      self.address = data.address1;
      self.apartment = data.address2 || '';
      self.city = data.city;
      self.state = data.state;
      self.postalCode = data.postalCode ? data.postalCode.trim() : '';
      self.isPoBox = data.poBox;
      self.isReceiveInformation = data.isReceiveInformation;
      self.country = getRoot(self).country;
      self.saveToServerSession();
    },

    getBillingData() {
      return {
        billingCountry: self.billingCountryRegion,
        billingAddress1: self.billingAddress,
        billingAddress2: self.billingApartment,
        billingCity: self.billingCity,
        billingState: self.billingState,
        billingPostalCode: self.billingPostalCode.trim(),
        isBillingAddressSame: self.isBillingAddressSame,
      };
    },

    setBillingData(data) {
      // Same as shipping
      self.billingFirstName = self.firstName;
      self.billingLastName = self.lastName;
      self.isBillingPoBox = self.isPoBox;

      // Form data
      self.billingAddress = data.billingAddress1;
      self.billingApartment = data.billingAddress2;
      self.billingCity = data.billingCity;
      self.billingState = data.billingState;
      self.billingTown = data.billingTown;
      self.billingPostalCode = data.billingPostalCode;

      self.isBillingAddressSame = data.isBillingAddressSame;
      self.billingCountryRegion = data.billingCountry;
      self.autoRenewMembership = data.autoRenewMembership;
      self.saveToServerSession();
    },

    getStoreState(shippingData) {
      const store = {};
      Object.assign(store, self);

      if (shippingData) {
        store.email = shippingData.email;
        store.telephone = shippingData.phone;
        store.firstName = shippingData.firstName;
        store.lastName = shippingData.lastName;
        store.address = shippingData.address1;
        store.apartment = shippingData.address2;
        store.city = shippingData.city;
        store.state = shippingData.state;
        store.postalCode = shippingData.postalCode;
        store.isPoBox = shippingData.poBox;
        store.isReceiveInformation = shippingData.isReceiveInformation;
        store.country = getRoot(self).country;
      }

      const products = getRoot(self).productsStore.localProducts;
      if (self.selectedBundle && self.selectedBundle !== 'bundleNone') {
        store.selectedBundle = products[self.selectedBundle].toObject;
      }

      if (self.membershipId) {
        store.membership = products.memberships.find(m => m.id === self.membershipId);
      }

      store.shippingMethod = products.shippingFees.find(
        sm => sm.metaMethod === self.shippingMethod,
      );
      store.computedPrice = self.computedPriceTotal;

      if (getRoot(self).authStore.isAuthenticated) {
        store.mobile = {
          isMobile: true,
          isMobileUser: true,
          customerId: getRoot(self).authStore.customerId,
          locationId: getRoot(self).authStore.locationId,
          deployment: getRoot(self).authStore.deployment,
          promotion: getRoot(self).authStore.promotion,
        };
      }

      store.estimatedDelivery = self.estimatedDelivery;

      return store;
    },
    setCreditCardLoadingStatus(status) {
      self.loadingCreditCardStatus = status;
    },
    setIsUseReferralCredits(data) {
      self.isUseReferralCredits = data.isUseReferralCredits;
      self.saveToServerSession();
    },
  }));
