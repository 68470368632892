// productName mapping is taken from /server/utils/zuora/ZuoraProductsMetaMap
// sort priority calculation is taken from
// /server/factories/hubspot/HubspotEmailFactory
// Logical and structural changes must be synced in server code
// respectively

export const PRODUCT_BUNDLE_UK = 'SKU-00000034'; // 2 Super Pods - UK Bundle

export const getRatePlanPriority = (ratePlan) => {
  if (!ratePlan.__metaMap) {
    return 5;
  }
  switch (ratePlan.__metaMap.productName) {
    case 'memberships':
      return 1;
    case 'bundle1SP':
    case 'bundleOne':
    case 'bundleTwo':
    case 'bundleThree':
    case 'bundleFour':
    case 'bundleTalkTalk':
      return 2;
    case 'superPod':
    case 'superPodDiscounted':
      return 3;
    case 'pod':
      return 4;
    case 'shippingFees':
    default:
      return 5;
  }
};

export const isRatePlanListable = (ratePlan) => {
  if (!ratePlan.__metaMap) {
    return false;
  }
  switch (ratePlan.__metaMap.productName) {
    case 'memberships':
    case 'bundle1SP':
    case 'bundleOne':
    case 'bundleTwo':
    case 'bundleThree':
    case 'bundleFour':
    case 'bundleTalkTalk':
    case 'superPod':
    case 'superPodDiscounted':
    case 'pod':
    case 'powerPod':
      return true;
    case 'shippingFees':
    default:
      return false;
  }
};

export const getABCaseLabel = (bundle) => {
  if (!bundle || bundle.sku !== PRODUCT_BUNDLE_UK) return false;

  const isVariantB = bundle.productRatePlans[0].name.indexOf('Variant B') > -1;
  return isVariantB ? '9+free UK Store' : '6+39 Starter Pack';
};

export const withMembership = ({ ratePlanName, productName }) => (
  ratePlanName.toLowerCase().indexOf('subscription') > -1 || productName.toLowerCase().indexOf('subscription') > -1
);
