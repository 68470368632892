import moment from 'moment';

export default (plans, startDate) => {
  let c = '';
  const value = plans.filter(ratePlan => ratePlan.lastChangeType !== 'Remove').reduce((acc, plan) => acc
    + plan.ratePlanCharges.reduce((a, charge) => {
      const { effectiveEndDate } = charge;
      if (
        charge.type === 'Recurring'
        // && today < effectiveEndDate
        && !charge.description.toLowerCase().includes('pre-claiming')
      ) {
        c = charge.currency;
        a += charge.latestCatalogPrice ? charge.latestCatalogPrice : charge.price;
      }
      if (
        charge.type === 'Recurring' // TODO take into account End Date
        && charge.applyDiscountTo === 'RECURRING'
        && moment(startDate).add(1, 'd').isBefore(effectiveEndDate)
        && !charge.description.toLowerCase().includes('pre-claiming')
      ) {
        c = charge.currency;
        a -= charge.discountAmount;
      }

      return a;
    }, 0), 0).toFixed(2).replace('.00', '');

  const discountValue = plans.reduce((acc, plan) => acc
    + plan.ratePlanCharges.reduce((a, charge) => {
      const { effectiveEndDate } = charge;
      if (
        charge.type === 'Recurring'
        && charge.applyDiscountTo === 'RECURRING'
        && moment(startDate).add(1, 'd').isBefore(effectiveEndDate)
        && !charge.description.toLowerCase().includes('pre-claiming')
      ) {
        c = charge.currency;
        a -= value * charge.discountPercentage / 100;
      }

      return a;
    }, 0), 0).toFixed(2).replace('.00', '');

  return [Number(value) + Number(discountValue), c];
};
