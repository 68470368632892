import { types } from 'mobx-state-tree';

export default types
  .model('AccountBillingAndPayment', {
    additionalEmailAddresses: types.optional(types.array(types.string), []),
    billCycleDay: types.maybeNull(types.number),
    currency: types.maybeNull(types.string),
    invoiceDeliveryPrefsEmail: types.maybeNull(types.boolean),
    invoiceDeliveryPrefsPrint: types.maybeNull(types.boolean),
    paymentGateway: types.maybeNull(types.string),
    paymentTerm: types.maybeNull(types.string),
  });
