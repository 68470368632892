import { types } from 'mobx-state-tree';

export default types
  .model('AccountBasicInfo', {
    AccountSource__c: types.maybeNull(types.string),
    Class__NS: types.maybeNull(types.string),
    CustomerId__NS: types.maybeNull(types.string),
    CustomerType__NS: types.maybeNull(types.string),
    Department__NS: types.maybeNull(types.string),
    EmailAddress__c: types.maybeNull(types.string),
    IntegrationId__NS: types.maybeNull(types.string),
    IntegrationStatus__NS: types.maybeNull(types.string),
    Location__NS: types.maybeNull(types.string),
    Login_Email__c: types.maybeNull(types.string),
    OriginCountry__c: types.maybeNull(types.string),
    PlumeCustomerID__c: types.maybeNull(types.string),
    Referral_Code_Used__c: types.maybeNull(types.string),
    Referral_Code__c: types.maybeNull(types.string),
    Subsidiary__NS: types.maybeNull(types.string),
    SyncDate__NS: types.maybeNull(types.string),
    SynctoNetSuite__NS: types.maybeNull(types.string),
    accountNumber: types.maybeNull(types.string),
    batch: types.maybeNull(types.string),
    communicationProfileId: types.maybeNull(types.string),
    crmId: types.maybeNull(types.string),
    id: types.maybeNull(types.string),
    invoiceTemplateId: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    notes: types.maybeNull(types.string),
    parentId: types.maybeNull(types.string),
    salesRep: types.maybeNull(types.string),
    sequenceSetId: types.maybeNull(types.string),
    serviceDeactivated__c: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    AccountType__c: types.maybeNull(types.string),
    ShopifyCustomerID__c: types.maybeNull(types.string),
  });
