import { types, getRoot } from 'mobx-state-tree';
import { setProductTypeIds } from 'utils/mobx';
import { fetchProducts } from 'api/products';
import { Products } from './models/Products';

export const ProductsStore = types
  .model('ProductsStore', {
    products: types.map(Products),
  })
  .actions(self => ({
    deleteProducts(country) {
      self.products.delete(country);
    },
    setProducts(country, data) {
      self.products.set(country, data);
    },
    async fetchProducts(country) {
      if (self.products.has(country)) {
        return;
      }
      self.setProducts(country, {
        loading: true,
      });
      try {
        const data = await fetchProducts(
          country,
          getRoot(self).authStore.promotion,
        );
        self.setProducts(country, {
          ...setProductTypeIds(data),
          loading: false,
        });
        getRoot(self).checkoutStore.setFirstAvailableMembership();
      } catch (error) {
        console.error(error);
        self.deleteProducts(country);
      }
    },
  }))
  .views(self => ({
    get localProducts() {
      return self.products.get(getRoot(self).country);
    },
  }));
