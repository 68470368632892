import React, { Component } from 'react';
import IconSpin from 'components/icons/Spin';

class Spinner extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <IconSpin className="spin" />
      </div>
    );
  }
}

export default Spinner;
