import moment from 'moment';
import { getRoot, types } from 'mobx-state-tree';
import {
  PRODUCT_NAME_SHIPPING_FEES,
  DISCOUNTS, SHIPPING_FREE,
  MEMBERSHIP_FIRST_CHARACTERS,
  MEMBERSHIP_ORDER,
} from 'constants/checkoutOptions';
import { getTwoDecimalNumber } from '../../utils/getDecimalNumber';
import { fetchInvoicePdf } from '../../api';

const InvoicePayment = types
  .model('InvoicePayment', {
    status: types.string,
    effectiveDate: types.string,
    paymentMethodId: types.string,
  });

const InvoiceItem = types
  .model('InvoiceItem', {
    chargeAmount: types.number,
    chargeDate: types.string,
    chargeDescription: types.string,
    chargeId: types.string,
    chargeName: types.string,
    chargeType: types.string,
    id: types.string,
    processingType: types.string,
    productName: types.string,
    quantity: types.number,
    serviceEndDate: types.string,
    serviceStartDate: types.string,
    subscriptionId: types.string,
    subscriptionName: types.string,
    taxAmount: types.number,
    unitOfMeasure: types.string,
  });

const Invoice = types
  .model('Invoice', {
    id: types.string,
    subscriptionId: types.string,
    subscriptionName: types.string,
    creditBalanceAdjustmentAmount: types.number,
    invoiceNumber: types.string,
    dueDate: types.string,
    invoiceDate: types.string,
    invoiceItems: types.array(InvoiceItem),
    amount: types.number,
    balance: types.number,
    payment: types.maybeNull(InvoicePayment),
    InvoiceReason__c: types.maybeNull(types.string),
    QrCode__c: types.maybeNull(types.string),
    WriteOffInvoice__c: types.maybeNull(types.string),
    base64Pdf: types.maybeNull(types.string),
    servicePeriod: types.maybeNull(types
      .model('InvoiceServicePeriod', {
        serviceStartDate: types.string,
        serviceEndDate: types.string,
      })),
  })
  .views(self => ({
    get paymentMethodInfo() {
      if (self.payment) {
        const { paymentMethodId } = self.payment;
        const { accountData } = getRoot(self).accountStore.localAccount;
        return accountData ? accountData.paymentInfo.getCreditCardById(paymentMethodId) : null;
      }
      return null;
    },
    get isUnpaid() {
      return self.balance !== 0;
    },
    get invoiceTotalWithReferral() {
      // TODO this calculation should be change for group memberships
      if (self.isOnlyMembershipList && self.withoutDiscountList.length > 1) {
        const [lastMembership] = self.lastRenewalMembership;
        return getTwoDecimalNumber(lastMembership.chargeAmount + lastMembership.taxAmount);
      }
      if (self.creditBalanceAdjustmentAmount) {
        return getTwoDecimalNumber(self.amount + self.creditBalanceAdjustmentAmount);
      }
      return getTwoDecimalNumber(self.amount);
    },
    get credits() {
      return self.creditBalanceAdjustmentAmount ? (
        getTwoDecimalNumber(self.creditBalanceAdjustmentAmount)
      ) : (
        self.creditBalanceAdjustmentAmount
      );
    },
    get withoutDiscountList() {
      return self.invoiceItems
        .filter(discountInvoice => discountInvoice.productName !== DISCOUNTS.DISCOUNT);
    },
    get discountList() {
      return self.invoiceItems
        .filter(discountInvoice => discountInvoice.productName === DISCOUNTS.DISCOUNT);
    },
    get lastRenewalMembership() {
      const [lastItem] = self.withoutDiscountList.slice().sort((a, b) => {
        if (new Date(a.serviceEndDate).getTime() > new Date(b.serviceEndDate).getTime()) {
          return -1;
        }
        return 1;
      });
      return [lastItem];
    },
    get isOnlyMembershipList() {
      return self.withoutDiscountList
        .every(invoice => invoice.chargeName
          .trim()
          .substring(0, 3) === MEMBERSHIP_FIRST_CHARACTERS);
    },
    get filteredChargesWithProduct() {
      if (self.isOnlyMembershipList) {
        return self.lastRenewalMembership;
      }
      return self.invoiceItems.filter(item => (
        item.productName !== PRODUCT_NAME_SHIPPING_FEES
        && item.productName !== DISCOUNTS.DISCOUNT
        && item.quantity !== 0
      ));
    },
    get sortedProductItems() {
      return self.filteredChargesWithProduct
        .slice()
        .sort((a) => {
          if (a.chargeName.trim().substring(0, 3) === MEMBERSHIP_FIRST_CHARACTERS) {
            return -1;
          }
          return 1;
        });
    },
    get subtotal() {
      const { country } = getRoot(self);

      const chargeAmount = self.filteredChargesWithProduct
        .reduce((acc, curr) => acc + curr.chargeAmount, 0);

      if (country === 'us') {
        return getTwoDecimalNumber(chargeAmount);
      }

      const sum = self.taxSubtotalAmount + chargeAmount;
      return getTwoDecimalNumber(sum);
    },
    get taxSubtotalAmount() {
      return self.filteredChargesWithProduct.reduce((acc, curr) => acc + curr.taxAmount, 0);
    },
    get taxAmount() {
      const tax = self.invoiceItems.reduce((acc, curr) => acc + curr.taxAmount, 0);
      return getTwoDecimalNumber(tax);
    },
    get discount() {
      const { country } = getRoot(self);
      const discount = self.discountList.reduce((acc, curr) => {
        if (country !== 'us') {
          return acc + curr.chargeAmount + curr.taxAmount;
        }
        return acc + curr.chargeAmount;
      }, 0);
      return discount ? getTwoDecimalNumber(discount) : discount;
    },
    get shippingType() {
      const shippingFeesPlan = self.invoiceItems.find(item => (
        item.productName === PRODUCT_NAME_SHIPPING_FEES
      ));
      if (shippingFeesPlan) {
        return shippingFeesPlan.chargeDescription.includes(SHIPPING_FREE) ? (
          'checkout:shipping-form.free'
        ) : (
          `${getRoot(self).checkoutStore.currencySign} ${getTwoDecimalNumber(shippingFeesPlan.chargeAmount)}`
        );
      }
      return null;
    },
  }))
  .actions(self => ({
    async fetchPdfInvoice(errorHandler) {
      const { accessToken } = getRoot(self).authStore;
      try {
        const response = await fetchInvoicePdf({
          accessToken,
          invoiceNumber: self.invoiceNumber,
        });
        self.updatePdf(response.data);
        return true;
      } catch (err) {
        if (errorHandler) {
          errorHandler(err.message || err.statusText);
        }
        return false;
      }
    },
    updatePdf(base64Pdf) {
      self.base64Pdf = base64Pdf;
    },
  }));

export const Invoices = types
  .model('Invoices', {
    invoices: types.array(Invoice),
    loading: false,
    loaded: false,
  })
  .views(self => ({
    invoicesOfSubscriptionByName(subscriptionName) {
      return self.invoices
        .filter(invoice => invoice.subscriptionName === subscriptionName);
    },
    lastKnownInvoice(subscriptionName) {
      return self.invoices
        .filter(invoice => invoice.subscriptionName === subscriptionName)
        .reduce((acc, current) => {
          if (!acc || !acc.dueDate) {
            return current;
          }
          if (moment(current.dueDate, 'YYYY-MM-DD')
            .isAfter(moment(acc.dueDate, 'YYYY-MM-DD'))) {
            return current;
          }
          return acc;
        }, null);
    },

    lastKnownPaymentStatus(subscriptionId) {
      const invoice = self.lastKnownInvoice(subscriptionId);
      if (!invoice || !invoice.payment) {
        return undefined;
      }
      return invoice.payment.status;
    },

    lastKnownPaymentDate(subscriptionId) {
      const invoice = self.lastKnownInvoice(subscriptionId);
      if (!invoice || !invoice.payment) {
        return undefined;
      }
      return invoice.payment.effectiveDate;
    },
    getInvoiceById(invoiceId) {
      return self.invoices.find(item => item.id === invoiceId);
    },
    getInvoiceByNumber(invoiceNumber) {
      return self.invoices.find(item => item.invoiceNumber === invoiceNumber);
    },
    get filteredInvoices() {
      return self.invoices.filter((item) => {
        if (item.InvoiceReason__c === MEMBERSHIP_ORDER) {
          return true;
        }
        // const firstThreeCharacters = item.invoiceItems[0].chargeName.trim().substring(0, 3);
        // if (item.invoiceItems.length === 1
        //   && firstThreeCharacters === MEMBERSHIP_FIRST_CHARACTERS
        // ) {
        //   return false;
        // }
        // if (item.invoiceItems.length > 1) {
        //   const withoutDiscountList = item.invoiceItems
        //     .filter(discountInvoice => discountInvoice.productName !== DISCOUNTS.DISCOUNT);
        //   const isOnlyMembershipList = withoutDiscountList
        //     .every(invoice => invoice.chargeName
        //       .trim()
        //       .substring(0, 3) === MEMBERSHIP_FIRST_CHARACTERS);
        //   return !isOnlyMembershipList;
        // }
        if (item.amount > 0) {
          return true;
        }
        return false;
      });
    },
  }));
