import { PaymentTypes } from 'constants';

export const optionsWithAffirm = [
  {
    id: PaymentTypes.CREDIT_CARD,
    title: 'Credit Card',
    subtitle: 'Pay Now',
  },
  {
    id: PaymentTypes.MONTHLY_PAYMENTS,
    title: 'Monthly Payments',
    subtitle: 'Using Affirm (Minimum $50 order)',
  },
];

export const optionsWithoutAffirm = [
  {
    id: PaymentTypes.CREDIT_CARD,
    title: 'payment.credit-card',
    subtitle: 'payment.pay-now',
  },
];

export const MEMBERSHIP = 'Membership';
export const SHIPPING_FREE = 'Free Standard Shipping';
export const PRODUCT_NAME_SHIPPING_FEES = 'Shipping Fees';
export const FREE = 'FREE';
export const SUPER_POD = 'SuperPod';
export const POWER_POD = 'PowerPod';
export const SILVER = 'silver';
export const SILVER_SUNRISE = 'silber';
export const DISCOUNTS = {
  DISCOUNT: 'Discount',
  DISCOUNTS: 'Discounts',
  CORPORATE_DISCOUNT: '710-DISC-001',
  QUARANTINE_DISCOUNT: '710-1007-00',
};
export const EXPRESS = 'Express';
export const MEMBERSHIP_FIRST_CHARACTERS = '710';
export const MEMBERSHIP_ORDER = 'New Order';
