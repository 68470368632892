import { types } from 'mobx-state-tree';
import { postNewsletterSubscription } from '../api';

// we don't need response from hubspot, so assume it's always success
// TODO move newsletter from submit to backend as proxy
const FORCE_SUCCESS = true;

export const NewsletterStore = types
  .model('NewsletterStore', {
    status: types.optional(types.string, 'idle'),
  })
  .views(self => ({
    isIdle() {
      return self.status === 'idle';
    },
    isPosting() {
      return self.status === 'posting';
    },
    postingSuccess() {
      return self.status === 'success';
    },
    postingError() {
      return self.status === 'error';
    },
  }))
  .actions(self => ({
    async postNewsletterSubscription(data, formId) {
      self.status = 'posting';

      const response = await postNewsletterSubscription(data, formId);

      if (FORCE_SUCCESS || response.request.status === 200) {
        self.postNewsletterSubscriptionSuccess();
      } else {
        self.postNewsletterSubscriptionError();
      }
    },

    postNewsletterSubscriptionSuccess() {
      self.status = 'success';
    },

    postNewsletterSubscriptionError() {
      self.status = 'error';
    },
  }));
