import axios from 'axios';
import { BACKEND_URL } from 'env';
import { doesPromotionExist } from 'constants';

export const fetchProducts = async (country, promotion) => {
  try {
    const params = doesPromotionExist(country, promotion) ? {
      country,
      promotion,
    } : {
      country,
    };

    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/products`,
      responseType: 'json',
      params,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};
