import { getRoot, types } from 'mobx-state-tree';
import jwtDecode from 'jwt-decode';
import { getCookie, deleteCookie } from '../utils/Cookies';

export const AuthStore = types
  .model('AuthStore', {
    accessToken: types.optional(types.string, ''),
  })
  .views(self => ({
    get isAuthenticated() {
      return self.accessToken !== '';
    },
    get jwtTokenData() {
      return self.isAuthenticated && jwtDecode(self.accessToken);
    },
    get isMobile() {
      return self.isAuthenticated && self.jwtTokenData.isMobile;
    },
    get customerId() {
      return self.isAuthenticated && self.jwtTokenData.customerId;
    },
    get locationId() {
      return self.isAuthenticated && self.jwtTokenData.locationId;
    },
    get deployment() {
      return self.isAuthenticated && self.jwtTokenData.deployment;
    },
    get promotion() {
      return self.isAuthenticated && self.jwtTokenData.promotion;
    },
    get membershipRequired() {
      return self.isAuthenticated && self.jwtTokenData.membershipRequired;
    },
  }))
  .actions(self => ({
    logout() {
      self.clearAccessToken();
      getRoot(self)
        .checkoutStore
        .resetStoreData();
    },
    setAccessToken() {
      const accessToken = getCookie('accessToken');
      if (accessToken) {
        self.accessToken = accessToken;
      } else {
        self.clearAccessToken();
      }
    },
    clearAccessToken() {
      self.accessToken = '';
      deleteCookie('accessToken');
      deleteCookie('acc_id');
    },
  }));
