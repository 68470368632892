import { types } from 'mobx-state-tree';

import CreditCard from './CreditCard';

export default types
  .model('AccountPaymentInfo', {
    creditCards: types.optional(types.array(CreditCard), []),
    success: types.optional(types.boolean, true),
  })
  .actions(self => ({
    setSortedCards(cards) {
      self.creditCards = cards;
    },
  }))
  .views(self => ({
    getCreditCardById(id) {
      const paymentMethod = self.creditCards.find(item => item.id === id);
      return paymentMethod || null;
    },
  }));
