import axios from 'axios';
import { BACKEND_URL } from 'env';

export const saveToServerSession = async (checkoutData) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/session`,
      responseType: 'json',
      data: {
        checkout: checkoutData,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const getServerSession = async (sessionId = null, country) => {
  // Appends the session & country cookies to the header data
  // This is necessary because this is strictly a server side request
  // and the sessionId cookie is not automatically appended
  const props = {
    headers: {
      Cookie: sessionId ? `connect.sid=${sessionId};next-i18next=${country}` : `next-i18next=${country}`,
    },
  };

  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/session`,
      responseType: 'json',
      withCredentials: true,
      ...props,

    });
    return response.data;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
