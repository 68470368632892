import { types, applySnapshot } from 'mobx-state-tree';
import { i18n } from 'server/i18n';

import { AuthStore } from './AuthStore';
import { AccountStore } from './AccountStore';
import { MagicLinkStore } from './MagicLinkStore';

import { NewsletterStore } from './NewsletterStore';
import { CheckoutStore } from './CheckoutStore';
import { InvoiceStore } from './InvoiceStore';
import { ProductsStore } from './ProductsStore';
import { UiStore } from './UiStore';

let storeContainer = null;

// eslint-disable-next-line
export let store = null;

try {
  storeContainer = types
    .model('Store', {
      accountStore: types.optional(AccountStore, {}),
      authStore: types.optional(AuthStore, {}),
      magicLinkStore: types.optional(MagicLinkStore, {}),
      newsletterStore: types.optional(NewsletterStore, {}),
      // checkoutStore: types.optional(CheckoutStore, {}),
      // invoiceStore: types.optional(InvoiceStore, {}),
      // productsStore: types.optional(ProductsStore, {}),
      uiStore: types.optional(UiStore, {}),
      i18nLanguage: types.optional(types.string, 'us'),
    })
    .views(self => ({
      get country() {
        return self.i18nLanguage;
      },
    }))
    .actions(self => ({
      afterCreate() {
        if (process.browser) {
          self.authStore.setAccessToken();
          i18n.on('languageChanged', self.updateLanguage);
        }
      },
      updateLanguage(lng) {
        self.i18nLanguage = lng;
      },
      async setStoreCountry(lng) {
        i18n.changeLanguage(lng, () => {
          // window.location.reload();
        });
        // await self.productsStore.fetchProducts(lng);
        // if (self.authStore.isAuthenticated) {
        //   await self.accountStore.fetchAccount(lng);
        // }
        // self.checkoutStore.setFirstAvailableMembership();
        // self.checkoutStore.setSelectedStarterPack('bundle1SP');
      },
    }));
} catch (e) {
  // eslint-disable-next-line no-console
  console.error('MOBX STORE ERROR: Check mobx state tree models and make prop values and types match.', e);
}

const Store = storeContainer;

export function initStore(snapshot = null) {
  const isServer = !process.browser;
  if (isServer) {
    store = Store.create();
  }
  if (store === null) {
    store = Store.create();
    if (snapshot) {
      applySnapshot(store, snapshot);
    }
  }

  if (isServer && snapshot) {
    applySnapshot(store, snapshot);
  }
  return store;
}
