import { types, getRoot, getParent } from 'mobx-state-tree';
import moment from 'moment';
import {
  patchAutoRenew,
  fetchSubscription,
  fetchSubscriptionPreviewData,
} from 'api/account';
import {
  getRatePlanPriority,
} from 'utils/RatePlanUtils';

import { DISCOUNTS } from 'constants/checkoutOptions';
import totalPlansAmount from '../../utils/totalPlansAmount';

import RatePlan from './RatePlan';

const ONE_DAY = 24 * 60 * 60 * 1000;
const DAYS_TO_RENEW = 45;

const sortPlans = (a, b) => (
  getRatePlanPriority(a) - getRatePlanPriority(b)
);


export default types
  .model('Subscription', {
    id: types.maybeNull(types.string),
    accountId: types.maybeNull(types.string),
    accountName: types.maybeNull(types.string),
    accountNumber: types.maybeNull(types.string),
    invoiceOwnerAccountId: types.maybeNull(types.string),
    invoiceOwnerAccountName: types.maybeNull(types.string),
    invoiceOwnerAccountNumber: types.maybeNull(types.string),
    subscriptionNumber: types.maybeNull(types.string),
    termType: types.maybeNull(types.string),
    invoiceSeparately: types.maybeNull(types.boolean),
    contractEffectiveDate: types.maybeNull(types.string),
    serviceActivationDate: types.maybeNull(types.string),
    customerAcceptanceDate: types.maybeNull(types.string),
    subscriptionStartDate: types.maybeNull(types.string),
    paymentType: types.maybeNull(types.string),
    termStartDate: types.maybeNull(types.string),
    termEndDate: types.maybeNull(types.string),
    initialTerm: types.maybeNull(types.number),
    initialTermPeriodType: types.maybeNull(types.string),
    currentTerm: types.maybeNull(types.number),
    currentTermPeriodType: types.maybeNull(types.string),
    autoRenew: types.maybeNull(types.boolean),
    renewalSetting: types.maybeNull(types.string),
    renewalTerm: types.maybeNull(types.number),
    renewalTermPeriodType: types.maybeNull(types.string),
    contractedMrr: types.maybeNull(types.number),
    totalContractedValue: types.maybeNull(types.number),
    notes: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    ShipmentTrackerId__c: types.maybeNull(types.string),
    QuoteNumber__QT: types.maybeNull(types.string),
    OpportunityName__QT: types.maybeNull(types.string),
    ShippingDate__c: types.maybeNull(types.string),
    // TODO: Unknown schema:
    // PartnerID__c: types.maybeNull(types.string),
    ShippingMethod__c: types.maybeNull(types.string),
    CreatedFromClaiming__c: types.maybeNull(types.string),
    IsRecurring__c: types.maybeNull(types.string),
    PlumeLocationID__c: types.maybeNull(types.string),
    TrackingNumber__c: types.maybeNull(types.string),
    ShipmentTrackerLink__c: types.maybeNull(types.string),
    PaymentType__c: types.maybeNull(types.string),
    DeliveryStatus__c: types.maybeNull(types.string),
    CpqBundleJsonId__QT: types.maybeNull(types.string),
    QuoteType__QT: types.maybeNull(types.string),
    SyncDate__NS: types.maybeNull(types.string),
    QuoteBusinessType__QT: types.maybeNull(types.string),
    shipToZipCode__c: types.maybeNull(types.string),
    PlumeDeployment__c: types.maybeNull(types.string),
    IntegrationStatus__NS: types.maybeNull(types.string),
    // ComputedPrice__c: types.maybeNull(types.string),
    // TODO: Unknown schema:
    // OrderType_c__c: types.maybeNull(types.string),
    SalesChannel__c: types.maybeNull(types.string),
    shipToAddress__c: types.maybeNull(types.string),
    Node_Ref_Id__c: types.maybeNull(types.string),
    shipToCountry__c: types.maybeNull(types.string),
    SalesOrder__NS: types.maybeNull(types.string),
    shipToState__c: types.maybeNull(types.string),
    OpportunityCloseDate__QT: types.maybeNull(types.string),
    TransactionId__c: types.maybeNull(types.string),
    shipToCity__c: types.maybeNull(types.string),
    Project__NS: types.maybeNull(types.string),
    IntegrationId__NS: types.maybeNull(types.string),
    taxAmount: types.maybeNull(types.string),
    TaxAmount__c: types.maybeNull(types.string),
    amount: types.optional(types.number, 0),
    computedPrice: types.maybeNull(types.string),
    ratePlans: types.optional(types.array(RatePlan), []),
    // TODO: This field does not appear in backend response:
    shipToAddress: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get SKUs() {
      const SKUs = [];
      self.ratePlans.forEach(({ ratePlanCharges }) => {
        ratePlanCharges.forEach(({ name }) => {
          SKUs.push(name);
        });
      });
      return SKUs;
    },
    get activeSKUs() {
      const SKUs = [];
      self.ratePlans.forEach(({ ratePlanCharges, lastChangeType }) => {
        if (lastChangeType !== 'Remove') {
          ratePlanCharges.forEach(({ name }) => {
            SKUs.push(name);
          });
        }
      });
      return SKUs;
    },
    get isVooSubscription() {
      return self.SKUs.includes('710-0037-MT');
    },
    get isVooSubscriptionMonthly() {
      return self.activeSKUs.includes('710-0037-MT');
    },
    get isVMSubscription() {
      const vmSKUs = [
        '710-0100-MT',
        '710-0110-MT',
        '710-0017-MT',
        '710-0019-MT',
        '710-0022-MT',
        '710-0110-MT',
        '710-0110-MT',
      ];

      return self.activeSKUs.some(sku => vmSKUs.includes(sku));
    },
    get isVMSubscriptionUpgradedMonthly() {
      const vmSKUs = [
        '710-0110-MT',
      ];

      return self.activeSKUs.some(sku => vmSKUs.includes(sku));
    },
    get isVMSubscriptionUpgradedAnnual() {
      const vmSKUs = [
        '710-0110-AN',
      ];

      return self.activeSKUs.some(sku => vmSKUs.includes(sku));
    },
    get isVOOSubscriptionUpgradedAnnual() {
      const vmSKUs = [
        '710-0080-AN',
      ];

      return self.activeSKUs.some(sku => vmSKUs.includes(sku));
    },
    get isAllInvoicesNonPaid() {
      const root = getRoot(self);
      const invoices = root.accountStore
        && root.accountStore.localInvoices
        && root.accountStore.localInvoices.invoices;

      for (let i = 0; i < invoices.length; i++) {
        if (invoices[i].balance === 0) { // at least one paid check
          return false;
        }
      }

      return true;
    },
    get isCorporateDiscount() {
      return self.SKUs.includes(DISCOUNTS.CORPORATE_DISCOUNT);
    },
    get invoiceQuarantineDiscount() {
      return self.SKUs.includes(DISCOUNTS.QUARANTINE_DISCOUNT);
    },
    get invoiceTotalQuarantineDiscountValue() {
      let acc = 0;
      if (self.invoiceQuarantineDiscount) {
        self.ratePlans.forEach(({ ratePlanCharges }) => {
          ratePlanCharges.forEach(({ name, discountAmount }) => {
            if (name === DISCOUNTS.QUARANTINE_DISCOUNT) {
              acc = discountAmount;
            }
          });
        });
        const { membership } = getParent(self);
        if (self.multiplier >= 0.5 && membership) {
          acc = membership.price - (membership.price * self.multiplier);
        }
      }
      return acc;
    },
    get invoiceQuarantineDiscountValue() {
      if (self.invoiceQuarantineDiscount) {
        return 50;
      }
      return 0;
    },
    get tax() {
      return getRoot(self).country === 'us' ? parseFloat(self.taxAmount) : 0;
    },
    get multiplier() {
      const discountPercentFiltered = self.ratePlans
        .filter(({ ratePlanName, productName }) => (
          ratePlanName.includes(DISCOUNTS.DISCOUNT)
            || productName.includes(DISCOUNTS.DISCOUNT)
        ))
        .find(item => item.name !== DISCOUNTS.QUARANTINE_DISCOUNT);

      if (discountPercentFiltered) {
        return discountPercentFiltered.ratePlanCharges[0].discountPercentage ? (
          parseFloat(discountPercentFiltered.ratePlanCharges[0].discountPercentage) / 100.00
        ) : 0;
      }
      return 0;
    },
  }))
  .actions(self => ({
    payLastInvoiceAndRenew() {
      console.log(self);
    },
    async payInvoiceAndRenew() {
      // todo
    },
    async patchAutoRenew(autoRenew, errorHandler) {
      const { accessToken } = getRoot(self).authStore;
      const subscriptionId = self.id;
      try {
        const response = await patchAutoRenew(
          accessToken,
          subscriptionId,
          autoRenew,
        );
        if (response.status === 200) {
          return Promise.resolve(response.data);
        }
        return false;
      } catch (err) {
        if (errorHandler) {
          errorHandler(err.message || err.statusText);
        }
        return false;
      }
    },
    async reload(newId) {
      const { accessToken } = getRoot(self).authStore;
      self.setLoading(true);
      const response = await fetchSubscription(
        accessToken,
        newId,
      );
      if (response.subscription.success === true) {
        self.setLoading(false);
        self.update(response.subscription);
        return Promise.resolve(true);
      }
      return false;
    },
    setAmount(amount) {
      self.amount = amount;
    },
    async fetchSubscriptionPreviewData() {
      const { accessToken } = getRoot(self).authStore;
      try {
        const data = await fetchSubscriptionPreviewData(accessToken, self.id);
        if (data.InvoiceDatas.length) {
          self.setAmount(data.InvoiceDatas[0].Invoice.Amount);
          return;
        }
      } catch (err) {
        console.log('err', err);
      }
    },
    setLoading(state) {
      self.loading = state;
    },
    update(data) {
      Object.keys(data).forEach((key) => {
        if (self.hasOwnProperty(key)) self[key] = data[key]; //eslint-disable-line
      });
    },
  }))
  .views(self => ({
    get root() {
      return getRoot(self);
    },
    get address1() {
      if (self.shipToAddress__c) {
        return self.shipToAddress__c.split('|')[0] || '';
      }
      return '';
    },
    get address2() {
      if (self.shipToAddress__c) {
        return self.shipToAddress__c.split('|')[1] || '';
      }
      return '';
    },
    get isAddressExist() {
      return Boolean(self.address1);
    },
    get isLifetime() {
      return self.termType === 'EVERGREEN';
    },
    get isBasic() {
      const [hasBasicMembership] = self.sortedRatePlans
        .filter(({ ratePlanName, lastChangeType }) => (
          ratePlanName.toLowerCase().includes('basic') && lastChangeType !== 'Remove'
        ));
      return Boolean(hasBasicMembership);
    },
    get isGrandfather() {
      const [hasGFMembership] = self.sortedRatePlans.filter(({ ratePlanName }) => (
        ratePlanName.toLowerCase().includes('grandfathered')
      ));
      return Boolean(hasGFMembership) && (self.termType === 'EVERGREEN');
    },
    get isClaimed() {
      return !!self.PlumeLocationID__c;
    },
    get isMonthly() {
      return self.renewalTerm === 1;
    },
    get isYearly() {
      return self.renewalTerm === 12;
    },
    get displayNameOfMembership() {
      const ratePlans = self.ratePlans.filter(({ ratePlanName }) => (
        ratePlanName.toLowerCase().includes('subscription')
      ));

      return ratePlans[ratePlans.length - 1].productName;
    },
    get displayNameOfActiveMembership() {
      const ratePlans = self.ratePlans.filter(({ ratePlanName }) => (
        ratePlanName.toLowerCase().includes('subscription')
      ));

      return ratePlans[ratePlans.length - 1].productName;
    },
    hasTermEnded() {
      return moment().isAfter(moment(self.termEndDate));
    },
    lastKnownInvoice() {
      const root = getRoot(self);
      return root.accountStore
        && root.accountStore.localInvoices
        && root.accountStore.localInvoices.lastKnownInvoice(self.subscriptionNumber);
    },
    nextRenewalDate() {
      if (self.termEndDate) {
        return moment(self.termEndDate);
      }
      const start = moment(self.termStartDate);
      const current = start;
      if (start) {
        if (self.isMonthly) {
          return current.add(1, 'M');
        }
        if (self.isYearly) {
          return current.add(1, 'y');
        }
      }
      return null;
    },

    get totalValue() {
      const [value] = totalPlansAmount(self.sortedRatePlans);
      return value;
    },
    get recurringPayment() {
      const [value, c] = totalPlansAmount(self.sortedRatePlans);

      switch (c) {
        case 'USD':
          return `$${value}`;
        case 'GBP':
          return `£${value}`;
        case 'CHF':
          return `CHF ${value}`;
        case 'EUR':
          return `€${parseFloat(value).toFixed(2).replace('.', ',')}`;
        default:
          return value;
      }
    },
    nextPaymentAmount(startDate) {
      const [value, c] = totalPlansAmount(self.sortedRatePlans, startDate);

      switch (c) {
        case 'USD':
          return `$${value}`;
        case 'GBP':
          return `£${value}`;
        case 'CHF':
          return `CHF ${value}`;
        default:
          return value;
      }
    },
    nextPaymentAmountNumber(startDate) {
      const [value] = totalPlansAmount(self.sortedRatePlans, startDate);
      return value;
    },
    get recurringPaymentNumber() {
      const [total] = totalPlansAmount(self.sortedRatePlans);
      return total;
    },
    get sortedRatePlans() {
      const {
        termStartDate,
        termEndDate,
      } = self;
      const start = moment(termStartDate);
      const end = moment(termEndDate);
      return self
        .ratePlans
        .slice()
        .reduce((accumulator, currentValue) => {
          const { ratePlanCharges } = currentValue;
          if (ratePlanCharges[0].description.toLowerCase().includes('grandfathered')) { // grandfathered
            accumulator.push(currentValue);
            return accumulator;
          }
          if (ratePlanCharges[0].description.toLowerCase().includes('lifetime')) { // lifetime
            accumulator.push(currentValue);
            return accumulator;
          }
          if (ratePlanCharges[0].description.toLowerCase().includes('basic')) { // basic
            if (currentValue.lastChangeType !== 'Remove') { // basic membership was not removed
              accumulator.push(currentValue);
              return accumulator;
            }
          }
          const endDate = moment(ratePlanCharges[0].effectiveEndDate);
          if (
            endDate.isBetween(start, end) || endDate.isSame(end) || endDate.isSame(start)
          ) {
            const accumulatorsRatePlans = accumulator.map(({ ratePlanCharges: r }) => r[0].name);
            if (!accumulatorsRatePlans.includes(currentValue.ratePlanCharges[0].name)) {
              accumulator.push(currentValue);
            }
          }
          return accumulator;
        }, [])
        .sort(sortPlans);
    },
    get sortedHistoryPlans() {
      return self
        .ratePlans
        .slice()
        .reduce((accumulator, currentValue) => {
          const { ratePlanCharges } = currentValue;
          if (ratePlanCharges[0].description.toLowerCase().includes('grandfathered')) { // grandfathered
            accumulator.push(currentValue);
            return accumulator;
          }
          if (ratePlanCharges[0].description.toLowerCase().includes('lifetime')) { // lifetime
            accumulator.push(currentValue);
            return accumulator;
          }
          const accumulatorsRatePlans = accumulator.map(({ ratePlanCharges: r }) => r[0].name);
          if (!accumulatorsRatePlans.includes(currentValue.ratePlanCharges[0].name)) {
            accumulator.push(currentValue);
          }
          return accumulator;
        }, [])
        .sort(sortPlans);
    },
    get numberOfDayTillRenew() {
      const renewDate = new Date(self.nextRenewalDate());
      const today = new Date();

      return Math.round((renewDate.getTime() - today.getTime())
        / (ONE_DAY));
    },
    get numberOfDayTillTermEnd() {
      const termEndDate = new Date(self.termEndDate);
      const today = new Date();

      return Math.round((termEndDate.getTime() - today.getTime())
        / (ONE_DAY));
    },
    get shouldDisplayRenew() {
      const autoRenewEnabled = self.autoRenew === true;
      return !autoRenewEnabled
        && self.numberOfDayTillTermEnd <= DAYS_TO_RENEW;
    },
    get invoices() {
      const subscriptionName = self.subscriptionNumber;
      const { accountStore } = getRoot(self);
      const invoices = accountStore && accountStore.localInvoices
        .invoicesOfSubscriptionByName(subscriptionName);
      return invoices || [];
    },
    get last_invoice() {
      const subscriptionName = self.subscriptionNumber;
      const { accountStore } = getRoot(self);
      const invoices = accountStore && accountStore.localInvoices
        .lastKnownInvoice(subscriptionName);
      return invoices || [];
    },
    get isAffirm() {
      return self.PaymentType__c === 'affirm';
    },
  }));
