import axios from 'axios';
import { BACKEND_URL } from 'env';

export const fetchPlaceDetails = async (placeId) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/google/places/${placeId}`,
      responseType: 'json',
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const fetchLatLngPlace = async (lat, lng) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/google/location`,
      responseType: 'json',
      params: {
        lat,
        lng,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
