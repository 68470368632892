import axios from 'axios';
import { BACKEND_URL } from 'env';

export const fetchAccount = async (accessToken, country) => {
  const params = country ? { country } : {};
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/api/account`,
      responseType: 'json',
      headers: { Authorization: accessToken },
      params,
    });
    return response.data;
  } catch (err) {
    if (err.status && err.status !== 404) {
      console.error(err);
    }
    throw err;
  }
};

export const fetchStripeData = async (accessToken, country) => {
  const params = country ? { country } : {};
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/api/account/stripe-data`,
      responseType: 'json',
      headers: { Authorization: accessToken },
      params,
    });
    return response.data;
  } catch (err) {
    if (err.status && err.status !== 404) {
      console.error(err);
    }
    throw err;
  }
};

export const fetchPaymentInfo = async (accessToken) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/api/account/paymentInfo`,
      responseType: 'json',
      headers: { Authorization: accessToken },
    });
    return response.data;
  } catch (err) {
    if (err.status && err.status !== 404) {
      console.error(err);
    }
    return Promise.reject(err.response);
  }
};

export const fetchUserContactDetails = async (accessToken) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/api/account/contacts`,
      responseType: 'json',
      headers: { Authorization: accessToken },
    });
    return response.data;
  } catch (err) {
    if (err.status && err.status !== 404) {
      console.error(err);
    }
    return Promise.reject(err.response);
  }
};

export const fetchSubscription = async (accessToken, id) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/api/account/subscription/${id}`,
      responseType: 'json',
      headers: { Authorization: accessToken },
    });
    return response.data;
  } catch (err) {
    if (err.status && err.status !== 404) {
      console.error(err);
    }
    throw err;
  }
};

export const patchAccountContactInfo = async (accessToken, soldToContactId, data) => {
  try {
    const response = await axios({
      method: 'PATCH',
      url: `${BACKEND_URL}/api/contacts/${soldToContactId}/basic-info`,
      responseType: 'json',
      headers: { Authorization: accessToken },
      data: {
        ...data,
      },
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const postAddressAdd = async (accessToken, data) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/api/contacts/`,
      responseType: 'json',
      headers: { Authorization: accessToken },
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const postAddressMakeDefault = async (accessToken, addressId) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/api/contacts/${addressId}/make-default`,
      responseType: 'json',
      headers: { Authorization: accessToken },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const deleteAddress = async (
  accessToken,
  addressId,
  defaultAddressId) => {
  try {
    const response = await axios({
      method: 'DELETE',
      // eslint-disable-next-line
      url: `${BACKEND_URL}/api/contacts/${addressId}?default_contact_id=${defaultAddressId}`,
      responseType: 'json',
      headers: { Authorization: accessToken },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const getPaymentMethods = async (accessToken) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/api/payment-method/`,
      responseType: 'json',
      headers: { Authorization: accessToken },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const postPaymentMethod = async (accessToken, refId) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/api/payment-method/`,
      responseType: 'json',
      headers: { Authorization: accessToken },
      data: {
        paymentMethodId: refId,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const postPaymentMethodDefault = async (accessToken, paymentMethodId, defaultGateway) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/api/payment-method/${paymentMethodId}/make-default?defaultGateway=${defaultGateway}`,
      responseType: 'json',
      headers: { Authorization: accessToken },
    });
    return response;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const deletePaymentMethod = async (accessToken, paymentMethodId, isDefault = false) => {
  try {
    const response = await axios({
      method: 'DELETE',
      url: `${BACKEND_URL}/api/payment-method/${paymentMethodId}?isDefault=${isDefault}`,
      responseType: 'json',
      headers: { Authorization: accessToken },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const patchAutoRenew = async (accessToken, subscriptionId, autoRenew) => {
  try {
    const response = await axios({
      method: 'PUT',
      url: `${BACKEND_URL}/subscription/auto-renewal`,
      responseType: 'json',
      headers: { Authorization: accessToken },
      data: {
        autoRenew,
        subscriptionId,
      },
    });
    return response;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const fetchInvoicePdf = async ({ accessToken, invoiceNumber }) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/invoice-pdf?invoiceNumber=${invoiceNumber}`,
      responseType: 'json',
      headers: { Authorization: accessToken },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const postPaymentMethodVerify = async (accessToken, paymentMethodId, currency, cvv) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/api/payment-method/verify`,
      responseType: 'json',
      headers: { Authorization: accessToken },
      data: {
        paymentMethodId,
        currency,
        cvv,
      },
    });
    return response;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const postRenewMembership = async ({
  accessToken,
  paymentMethodId,
  amount,
  invoiceNumber,
  creditBalance,
}) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/api/account/membership-renew`,
      responseType: 'json',
      headers: { Authorization: accessToken },
      data: {
        paymentMethodId,
        amount,
        invoiceNumber,
        creditBalance,
      },
    });
    return response;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const fetchSubscriptionPreviewData = async (accessToken, subscriptionId) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/api/account/subscription-preview-data/${subscriptionId}`,
      responseType: 'json',
      headers: { Authorization: accessToken },
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};


export const postUpgradeMembership = async ({
  accessToken,
  paymentMethodId,
  subscriptionId,
}) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/api/account/membership-upgrade`,
      responseType: 'json',
      headers: { Authorization: accessToken },
      data: {
        paymentMethodId,
        subscriptionId,
      },
    });
    return response;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const postUpgradeVooMembership = async ({
  accessToken,
  paymentMethodId,
  subscriptionId,
}) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/api/account/membership-voo-annual`,
      responseType: 'json',
      headers: { Authorization: accessToken },
      data: {
        paymentMethodId,
        subscriptionId,
      },
    });
    return response;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const postUpgradeVM3Membership = async ({
  accessToken,
  paymentMethodId,
  subscriptionId,
  newPlan,
}) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/api/account/membership-vm3-update`,
      responseType: 'json',
      headers: { Authorization: accessToken },
      data: {
        paymentMethodId,
        subscriptionId,
        newPlan,
      },
    });
    return response;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const postRenewMembershipInAdvance = async ({
  accessToken,
  paymentMethodId,
  subscriptionId,
}) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/api/account/membership-renew-in-advance`,
      responseType: 'json',
      headers: { Authorization: accessToken },
      data: {
        paymentMethodId,
        subscriptionId,
      },
    });
    return response;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const fetchCouponData = async (couponCode) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/checkout/coupon?code=${couponCode}`,
      responseType: 'json',
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const fetchCouponShopifyData = async (accessToken) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/api/account/shopify/coupon`,
      responseType: 'json',
      headers: { Authorization: accessToken },
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};
