
export const PRODUCT_TYPE_INDEX = {
  POD: 'pod',
  SUPER_POD: 'superPod',
  POWER_POD: 'powerPod',
  SMART_THINGS: 'smartThings',
};

export const PRODUCT_TYPES = {
  pod: {
    id: 'pod',
    speed: 'Fast',
    name: 'Pod',
    description: 'Covers up to 1 room',
    imageUrl: '/static/images/sku/single-pod-silver.png',
    imageBaseNameUrl: 'single-pod-plum',
    qtyName: {
      singular: 'Pod',
      plural: 'Pods',
    },
  },
  superPod: {
    id: 'superPod',
    speed: 'SuperFast',
    name: 'SuperPod',
    description: 'Covers up to 2 rooms',
    imageUrl: '/static/images/sku/single-superpod-silver.png',
    imageBaseNameUrl: 'single-superpod-plum',
    qtyName: {
      singular: 'SuperPod',
      plural: 'SuperPods',
    },
  },
  powerPod: {
    id: 'powerPod',
    speed: 'Fast',
    name: 'PowerPod',
    description: 'Covers 1 room',
    imageUrl: '/static/images/sku/single-superpod-silver.png',
    imageBaseNameUrl: 'single-powerpod-plum',
    qtyName: {
      singular: 'PowerPod',
      plural: 'PowerPods',
    },
  },
  smartThings: {
    id: 'smartThings',
    speed: 'Fast',
    name: 'SmartThings WiFi',
    description: 'Add coverage and  IoT control',
    imageUrl: '/static/images/sku/single-smartthings.png',
    imageBaseNameUrl: 'single-powerpod-plum',
    qtyName: {
      singular: 'SmartThings WiFi',
      plural: 'SmartThings WiFi',
    },
  },
};

export const getProductType = (typeId) => {
  const type = PRODUCT_TYPES[typeId];
  return type;
};

export const getGalleryImages = (bundle, color) => [
  `/sku/gallery/${bundle.imageBaseNameUrl}-${color.id}.jpg`,
  `/sku/gallery/${bundle.imageBaseNameUrl}-${color.id}-02.jpg`,
];

export const getProductPrice = (product) => {
  if (!product) {
    return null;
  }

  if (!Array.isArray(product)) {
    product = [product];
  }

  try {
    if (product[0].productRatePlanCharges) {
      return product[0].productRatePlanCharges[0].pricing[0].price;
    }
    return product[0].productRatePlans[0].productRatePlanCharges[0].pricing[0].price;
  } catch (e) {
    return null;
  }
};

export const getProductField = (product, field, defaultValue = null) => {
  if (!product || !field) {
    return defaultValue;
  }

  if (!Array.isArray(product)) {
    product = [product];
  }

  try {
    if (typeof product[0][field] === 'undefined') {
      return defaultValue;
    }
    return product[0][field];
  } catch (e) {
    return defaultValue;
  }
};

export const getProductPlanField = (product, field, defaultValue = null) => {
  if (!product) {
    return defaultValue;
  }

  if (!Array.isArray(product)) {
    product = [product];
  }

  try {
    if (product[0].productRatePlanCharges) {
      return product[0].productRatePlanCharges[0][field];
    }
    return product[0].productRatePlans[0][field];
  } catch (e) {
    return defaultValue;
  }
};
