
import { types } from 'mobx-state-tree';
import Bundle from './Bundle';
import Membership from './Membership';
import Pod from './Pod';
import ShippingFee from './ShippingFee';

export default types
  .model('InvoicePaymentProducts', {
    bundleFour: types.maybeNull(Bundle),
    bundle1SP: types.maybeNull(Bundle),
    bundleOne: types.maybeNull(Bundle),
    bundleTalkTalk: types.maybeNull(Bundle),
    bundleThree: types.maybeNull(Bundle),
    bundleTwo: types.maybeNull(Bundle),
    bundles: types.optional(types.array(Bundle), []),
    memberships: types.optional(types.array(types.maybeNull(Membership)), []),
    pod: types.maybeNull(Pod),
    shippingFees: types.optional(types.array(ShippingFee), []),
    superPod: types.maybeNull(Pod),
    powerPod: types.maybeNull(Pod),
    smartThings: types.maybeNull(Pod),
  });
