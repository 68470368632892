import { types } from 'mobx-state-tree';

import AccountAdditionalContact from './AccountAdditionalContact';
import AccountBasicInfo from './AccountBasicInfo';
import AccountBillingAndPayment from './AccountBillingAndPayment';
import AccountContactInfo from './AccountContactInfo';
import AccountPaymentInfo from './AccountPaymentInfo';
import AccountTaxInfo from './AccountTaxInfo';
import Subscription from './Subscription';

export const AccountData = types
  .model('AccountData', {
    basicInfo: types.frozen(AccountBasicInfo),
    billToContact: types.frozen(AccountContactInfo),
    billingAndPayment: types.frozen(AccountBillingAndPayment),
    contacts: types.frozen(types.array(AccountAdditionalContact)),
    metrics: types.frozen(types.model({
      balance: types.number,
      contractedMrr: types.number,
      creditBalance: types.number,
      totalInvoiceBalance: types.number,
    })),
    paymentInfo: types.maybeNull(AccountPaymentInfo),
    soldToContact: types.frozen(AccountContactInfo),
    subscriptions: types.optional(types.array(Subscription), []),
    success: types.frozen(types.boolean),
    taxInfo: types.maybeNull(AccountTaxInfo),
    acc_id: types.frozen(types.string),
    useStripeDefault: types.maybeNull(types.boolean),
    stripeCus: types.maybeNull(types.string),
  })
  .actions(self => ({
    setSoldToContractInfo(data) {
      self.soldToContact = { ...self.soldToContact, ...data };
    },
    deleteContact(contactId) {
      self.contacts = self.contacts.filter(contact => contact.Id !== contactId);
    },
  }))
  .views(self => ({
    getSubscriptionById(subscriptionName) {
      return self.subscriptions.find(item => item.subscriptionNumber === subscriptionName);
    },
  }));
