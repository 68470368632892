import {
  COUNTRY_US,
  COUNTRY_JP,
  COUNTRY_UK,
  COUNTRY_CA,
  COUNTRY_DE,
  COUNTRY_CH,
} from 'constants';

const promotions = {
  [COUNTRY_US.value]: [],
  [COUNTRY_JP.value]: [],
  [COUNTRY_UK.value]: [
    'talktalk',
    'vm',
  ],
  [COUNTRY_CA.value]: [],
  [COUNTRY_DE.value]: [],
  [COUNTRY_CH.value]: [
    'sunrise',
  ],
};

export const doesPromotionExist = (
  country, promotion,
) => promotions[country]
  && promotions[country].find(p => p === promotion);

// amount of credits that is given to coupon owner for one referral coupon usage
export const referralCredits = {
  [COUNTRY_US.value]: 20,
};
