import axios from 'axios';
import { BACKEND_URL } from 'env';

export const fetchZuoraRenderParams = async (country) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/checkout/signature?country=${country}`,
      responseType: 'json',
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const postCouponCode = async (coupon, checkout, accessToken) => {
  const { CancelToken } = axios;
  postCouponCode.source = CancelToken.source();
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/checkout/coupon`,
      headers: { Authorization: accessToken },
      responseType: 'json',
      data: {
        code: coupon,
        checkout,
      },
      cancelToken: postCouponCode.source.token,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const deleteCouponCode = async () => {
  try {
    const response = await axios({
      method: 'DELETE',
      url: `${BACKEND_URL}/checkout/coupon`,
      responseType: 'json',
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const getPaymentInfo = async (refId) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/checkout/payment-info`,
      responseType: 'json',
      params: {
        refId,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const getEstimatedArrival = async (shippingDate, shippingMethod, country) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/checkout/estimated-arrival`,
      responseType: 'json',
      params: {
        shippingDate,
        shippingMethod,
        country,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const completePayment = async (paymentType, params, data) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/checkout/legacy-param/${paymentType}`,
      responseType: 'json',
      params,
      data,
    });
    return response.data;
  } catch (err) {
    console.error('Incomplete Payment: ', err);
    return err.response;
  }
};

export const fetchTaxes = async (params, data) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/checkout/taxes`,
      responseType: 'json',
      params,
      data,
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export const saveDeal = async (data) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${BACKEND_URL}/save-deal`,
      responseType: 'json',
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
