import { types, getRoot, applyPatch } from 'mobx-state-tree';
import { ZUORA_PAYMENT_GATEWAY_LIST } from 'env';
import {
  fetchAccount,
  patchAccountContactInfo,
  postAddressMakeDefault,
  deleteAddress,
  postAddressAdd,
  postPaymentMethodDefault,
  deletePaymentMethod,
  getPaymentMethods,
  postPaymentMethod,
  postPaymentMethodVerify, postRenewMembership, postRenewMembershipInAdvance,
  postUpgradeMembership,
  postUpgradeVooMembership,
  fetchPaymentInfo,
  fetchUserContactDetails,
  postUpgradeVM3Membership,
} from 'api';

import {
  withMembership,
} from 'utils/RatePlanUtils';

import { AccountData } from './AccountData';

const ONE_DAY = 24 * 60 * 60 * 1000;
const DAYS_TO_RENEW = 45;

const compareCaseInsensitive = (str1, str2) => {
  if (!str1 && !str2) {
    return true;
  }
  if (!str1 || !str2) {
    return false;
  }
  return str1.toUpperCase() === str2.toUpperCase();
};

export const Account = types
  .model('Account', {
    loading: types.optional(types.boolean, false),
    accountData: types.maybeNull(AccountData),
    updating: types.optional(types.boolean, false),
    sendingIndexAddress: types.optional(types.number, -1),
    sendingIndexPayment: types.optional(types.number, -1),
    addressesFadedOut: types.optional(types.boolean, false),
    paymentsFadedOut: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setLoading(value) {
      self.loading = value;
    },
    setUpdating(value) {
      self.updating = value;
    },
    setSendingIndexAddress(value) {
      self.sendingIndexAddress = value;
    },
    setSendingIndexPayment(value) {
      self.sendingIndexPayment = value;
    },
    fadeAddressesOut() {
      self.addressesFadedOut = true;
    },
    fadeAddressesIn() {
      self.addressesFadedOut = false;
    },
    fadePaymentOut() {
      self.paymentsFadedOut = true;
    },
    fadePaymentIn() {
      self.paymentsFadedOut = false;
    },
    async getPaymentMethods() {
      const { accessToken } = getRoot(self).authStore;
      try {
        await getPaymentMethods(accessToken);
      } catch (error) {
        console.error(error);
      }
    },
    async reload(path) {
      const { accessToken } = getRoot(self).authStore;
      self.updating = true;
      try {
        const response = await fetchAccount(accessToken, getRoot(self).country);
        switch (path) {
          case '/accountData/paymentInfo/add':
            applyPatch(self, {
              op: 'replace',
              path: '/accountData/paymentInfo',
              value: response.paymentInfo,
            });
            self.sendingIndexPayment(-1);
            break;
          case '/accountData/subscriptions/setAutoRenew':
          case '/accountData/subscriptions/payInvoiceAndRenew':
          case '/accountData/subscriptions/renewInAdvance':
          case '/accountData/subscriptions/paymentMethodVerify':
            applyPatch(self, {
              op: 'replace',
              path: '/accountData/subscriptions',
              value: response.subscriptions,
            });
            break;
          default:
            break;
        }
        self.setUpdating(false);
      } catch (error) {
        console.error(error);
        self.setUpdating(false);
      }
    },
    async patchAccountContactInfo(accountId, data) {
      self.setUpdating(true);
      const { accessToken } = getRoot(self).authStore;
      try {
        await patchAccountContactInfo(
          accessToken, accountId, data,
        );
        await self.updateUserContactDetails();
        self.setUpdating(false);
        return Promise.resolve(true);
      } catch (error) {
        self.setUpdating(false);
        return Promise.reject(error.data);
      }
    },
    async updateUserContactDetails() {
      const { accessToken } = getRoot(self).authStore;
      try {
        const { accountDetails } = await fetchUserContactDetails(accessToken);
        applyPatch(self, {
          op: 'replace',
          path: '/accountData/contacts',
          value: accountDetails.contacts,
        });
        applyPatch(self, {
          op: 'replace',
          path: '/accountData/soldToContact',
          value: accountDetails.soldToContact,
        });
        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async postAddressMakeDefault(id, index) {
      self.setUpdating(true);
      self.setSendingIndexAddress(index);
      const { accessToken } = getRoot(self).authStore;
      try {
        await postAddressMakeDefault(accessToken, id);
        await self.updateUserContactDetails();
        self.fadeAddressesOut();
        self.setUpdating(false);
        return Promise.resolve(true);
      } catch (error) {
        self.setUpdating(false);
        self.setSendingIndexAddress(-1);
        return Promise.resolve(true);
      }
    },
    async deleteAddress(id, index, defaultAddress) {
      self.setUpdating(true);
      self.setSendingIndexAddress(index);
      const { accessToken } = getRoot(self).authStore;
      try {
        await deleteAddress(accessToken, id, defaultAddress);
        self.accountData.deleteContact(id);
        self.setUpdating(false);
        self.setSendingIndexAddress(-1);
        return Promise.resolve(true);
      } catch (error) {
        self.setUpdating(false);
        self.setSendingIndexAddress(-1);
        return Promise.resolve(true);
      }
    },
    async postAddressAdd(data) {
      self.setUpdating(true);
      self.setSendingIndexAddress(-1);
      const { accessToken } = getRoot(self).authStore;
      switch (getRoot(self).country) {
        case 'ch':
          data.Country = 'Switzerland';
          break;
        case 'uk':
          data.Country = 'United Kingdom';
          break;
        case 'us':
          data.Country = 'United States';
          break;
        case 'be':
          data.Country = 'Belgium';
          break;
        default:
          data.Country = 'United States';
      }

      try {
        await postAddressAdd(accessToken, data);
        const { accountDetails } = await fetchUserContactDetails(accessToken);
        applyPatch(self, {
          op: 'replace',
          path: '/accountData/contacts',
          value: accountDetails.contacts,
        });
        self.setSendingIndexAddress(-1);
        self.setUpdating(false);
        return Promise.resolve(true);
      } catch (error) {
        console.error(error);
        self.setSendingIndexAddress(-1);
        self.setUpdating(false);
        return Promise.resolve(true);
      }
    },
    updatePaymentMethodStatus(cardId) {
      const paymentCardsList = (
        self.accountData.paymentInfo && self.accountData.paymentInfo.creditCards
      );
      const oldDefaultCard = paymentCardsList.find(item => item.defaultPaymentMethod === true);
      const newPaymentCard = paymentCardsList.find(item => item.id === cardId);
      if (oldDefaultCard) {
        oldDefaultCard.defaultPaymentMethod = false;
      }
      newPaymentCard.defaultPaymentMethod = true;
    },
    async postPaymentMethodDefault(id, index, activeTab = 'stripe') {
      self.setUpdating(true);
      self.setSendingIndexPayment(index);
      const { accessToken } = getRoot(self).authStore;
      try {
        const { country } = getRoot(self);
        const postfix = self.isVMCustomer ? 'vm3' : '';
        const defaultGateway = ZUORA_PAYMENT_GATEWAY_LIST[activeTab][country + postfix];
        await postPaymentMethodDefault(accessToken, id, defaultGateway);
        self.setUpdating(false);
        self.updatePaymentMethodStatus(id);
        return Promise.resolve(true);
      } catch (error) {
        self.setUpdating(false);
        self.setSendingIndexPayment(-1);
        return Promise.reject(error.message);
      }
    },
    async deletePaymentMethod(id, index, isDefault) {
      self.setUpdating(true);
      self.setSendingIndexPayment(index);
      const { accessToken } = getRoot(self).authStore;
      try {
        const response = await deletePaymentMethod(accessToken, id, isDefault);
        if (response.success) {
          const paymentCardsList = (
            self.accountData.paymentInfo && self.accountData.paymentInfo.creditCards
          );
          if (paymentCardsList) {
            const updatedPaymentList = paymentCardsList.filter(card => card.id !== id);
            self.accountData.paymentInfo.setSortedCards(updatedPaymentList);
          }
        }
        self.setUpdating(false);
        self.setSendingIndexPayment(-1);
        return response;
      } catch (error) {
        self.setUpdating(false);
        self.setSendingIndexPayment(-1);
        return Promise.resolve(true);
      }
    },
    async postPaymentMethod(id) {
      self.setUpdating(true);
      self.setSendingIndexPayment(-1);
      const { accessToken } = getRoot(self).authStore;
      try {
        await postPaymentMethod(accessToken, id);
        return self.reload('/accountData/paymentInfo/add');
      } catch (error) {
        self.setUpdating(false);
        return Promise.resolve(true);
      }
    },
    async zuoraAddNewPaymentMethodSubmit(paymentMethodId, makeDefaultPaymentMethod, activeTab) {
      const { accessToken } = getRoot(self).authStore;
      self.setUpdating(true);
      try {
        const response = await fetchPaymentInfo(accessToken);
        applyPatch(self, {
          op: 'replace',
          path: '/accountData/paymentInfo',
          value: response.paymentInfo,
        });
        self.setUpdating(false);
        if (makeDefaultPaymentMethod) {
          return self.postPaymentMethodDefault(paymentMethodId, -1, activeTab);
        }
      } catch (error) {
        self.setUpdating(false);
        console.error('zuoraAddNewPaymentMethodSubmit error', error);
      }
      return true;
    },
    async payInvoiceAndRenew(invoice, paymentMethod, creditBalance = false) {
      const { accessToken } = getRoot(self).authStore;

      const response = await postRenewMembership({
        accessToken,
        paymentMethodId: paymentMethod,
        amount: Math.abs(invoice.balance),
        invoiceNumber: invoice.invoiceNumber,
        creditBalance,
      });

      if (response.status === 200) {
        self.setUpdating(false);
        return Promise.resolve(true);
      }

      self.setUpdating(false);
      return Promise.reject(new Error('Failed to pay'));
    },
    async upgradeMembership(subscriptionId, paymentMethod) {
      const { accessToken } = getRoot(self).authStore;

      const response = await postUpgradeMembership({
        accessToken,
        paymentMethodId: paymentMethod,
        subscriptionId,
      });

      if (response.status === 200) {
        self.setUpdating(false);
        return Promise.resolve(response.data.upgradeSubscriptionData);
      }

      self.setUpdating(false);
      return Promise.reject(new Error('Failed to pay'));
    },
    async upgradeVooMembership(subscriptionId, paymentMethod) {
      const { accessToken } = getRoot(self).authStore;

      const response = await postUpgradeVooMembership({
        accessToken,
        paymentMethodId: paymentMethod,
        subscriptionId,
      });

      if (response.status === 200) {
        self.setUpdating(false);
        return Promise.resolve(response.data.adjustPriceData);
      }

      self.setUpdating(false);
      return Promise.reject(new Error('Failed to pay'));
    },
    async upgradeVM3Membership(subscriptionId, paymentMethod, newPlan) {
      const { accessToken } = getRoot(self).authStore;

      const response = await postUpgradeVM3Membership({
        accessToken,
        paymentMethodId: paymentMethod,
        subscriptionId,
        newPlan,
      });

      if (response.status === 200) {
        self.setUpdating(false);
        return Promise.resolve(response.data);
      }

      self.setUpdating(false);
      return Promise.reject(new Error('Failed to pay'));
    },
    async renewInAdvance(subscriptionId, paymentMethod) {
      const { accessToken } = getRoot(self).authStore;

      const response = await postRenewMembershipInAdvance({
        accessToken,
        paymentMethodId: paymentMethod,
        subscriptionId,
      });

      if (response.status === 200) {
        self.setUpdating(false);
        return Promise.resolve(response.data.renewSubscriptionData);
      }

      self.setUpdating(false);
      return Promise.reject(new Error('Failed to pay'));
    },
    async postPaymentMethodVerify(paymentMethodId, currency, cvv) {
      self.setUpdating(true);
      const { accessToken } = getRoot(self).authStore;

      const response = await postPaymentMethodVerify(
        accessToken,
        paymentMethodId,
        currency,
        cvv,
      );

      if (response.data && response.data.success) {
        // self.reload('/accountData/subscriptions/paymentMethodVerify');
        return { success: true };
      }
      self.setUpdating(false);
      return { success: false, message: response.data.reasons[0].message };
    },
  }))
  .views(self => ({
    get hasFetched() {
      return self.accountData
        && self.accountData.basicInfo !== undefined;
    },

    get isAutoRenewed() {
      return self.termedSubscriptions
        .every(termedSubscription => termedSubscription.autoRenew === true);
    },

    get membershipSubscriptions() {
      if (self.accountData) {
        return self.accountData.subscriptions.filter((subscription) => {
          const { sortedRatePlans } = subscription;
          const isActive = subscription
            && subscription.DeliveryStatus__c
            && subscription.DeliveryStatus__c.toLowerCase() === 'active'
            && subscription.status.toLowerCase() !== 'cancelled';
          const hasMembership = sortedRatePlans.filter(withMembership).length > 0;
          return hasMembership && isActive;
        });
      }
      return [];
    },

    get termedSubscriptions() {
      return self.membershipSubscriptions
        .filter(subscription => subscription.termType === 'TERMED');
    },

    get isVMCustomer() {
      const {
        crmId,
        // eslint-disable-next-line camelcase
        ClaimingPartnerID__c,
      } = self.accountData.basicInfo;
      if (
        crmId === '60c9f6b74f7e9e638f974b82'
        // eslint-disable-next-line camelcase
        || ClaimingPartnerID__c === '60c9f6b74f7e9e638f974b82'
      ) {
        return true;
      }
      return false;
    },

    get isVooCustomer() {
      const {
        // eslint-disable-next-line camelcase
        AccountType__c,
      } = self.accountData.basicInfo;

      // eslint-disable-next-line camelcase
      if (AccountType__c === 'Voo Retail - Upgrade') {
        return true;
      }

      return false;
    },

    numberOfDayTillTermEnd() {
      const closestTermEndDate = self.termedSubscriptions
        .map(sub => sub.termEndDate)
        .reduce((prev, curr) => ((new Date(curr) < new Date(prev))
          ? curr : prev));

      const termEndDate = new Date(closestTermEndDate);
      const today = new Date();

      return Math.round(Math.abs((today.getTime() - termEndDate.getTime())
        / (ONE_DAY)));
    },

    shouldDisplayRenew() {
      const autoRenewEnabled = self.termedSubscriptions
        .every(termedSubscription => termedSubscription.autoRenew === true);
      return !autoRenewEnabled
       && self.numberOfDayTillTermEnd() <= DAYS_TO_RENEW;
    },

    get lastSubscription() {
      if (self.accountData.subscriptions.length > 0) {
        return self.accountData.subscriptions[0];
      }
      return undefined;
    },

    get sortedContacts() {
      const contacts = self.accountData.contacts.reduce((acc, address) => {
        if (address.Id === self.accountData.soldToContact.id) {
          return [address, ...acc];
        }
        return [...acc, address];
      }, []);

      return contacts;
    },
    get defaultPaymentMethod() {
      if (!self.accountData) return null;
      if (!self.accountData.paymentInfo) {
        return null;
      }
      const { paymentInfo } = self.accountData;
      const defaultCard = paymentInfo
        && paymentInfo.creditCards.find(paymentMethod => paymentMethod.defaultPaymentMethod);
      return defaultCard || null;
    },
    get sortedPaymentMethods() {
      const { paymentInfo } = self.accountData;
      if (!paymentInfo) {
        return [];
      }
      const cards = paymentInfo
        && paymentInfo.creditCards.reduce((acc, paymentMethod) => {
          if (paymentMethod.defaultPaymentMethod) {
            return [paymentMethod, ...acc];
          }
          return [...acc, paymentMethod];
        }, []);
      return cards;
    },

    get sortedUniqueContacts() {
      const { sortedContacts } = self;
      const uniqueContacts = sortedContacts.reduce((past, current) => {
        const x = past
          .find(contact => compareCaseInsensitive(contact.Address1, current.Address1)
          && compareCaseInsensitive(contact.Address2, current.Address2)
          && compareCaseInsensitive(contact.FirstName, current.FirstName)
          && compareCaseInsensitive(contact.LastName, current.LastName)
          && compareCaseInsensitive(contact.PostalCode, current.PostalCode)
          && compareCaseInsensitive(contact.State, current.State)
          && compareCaseInsensitive(contact.Country, current.Country)
          && compareCaseInsensitive(contact.City, current.City));

        if (!x) {
          return past.concat([current]);
        }

        return past;
      }, []);
      return uniqueContacts;
    },
  }));
