import { types } from 'mobx-state-tree';

export default types
  .model('Pricing', {
    currency: types.string,
    // discountAmount: null
    // discountPercentage: null
    includedUnits: types.maybeNull(types.number),
    // overagePrice: null
    price: types.number,
    // tiers: null
  });
