import { types } from 'mobx-state-tree';

export default types
  .model('AccountAdditionalContact', {
    Address1: types.maybeNull(types.string),
    Address2: types.maybeNull(types.string),
    City: types.maybeNull(types.string),
    Country: types.maybeNull(types.string),
    FirstName: types.maybeNull(types.string),
    Id: types.maybeNull(types.string),
    LastName: types.maybeNull(types.string),
    PostalCode: types.maybeNull(types.string),
    State: types.maybeNull(types.string),
    WorkEmail: types.maybeNull(types.string),
  });
