import { types } from 'mobx-state-tree';

export default types
  .model('AccountContactInfo', {
    address1: types.maybeNull(types.string),
    address2: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    contactDescription: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    county: types.maybeNull(types.string),
    fax: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    homePhone: types.maybeNull(types.string),
    id: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    mobilePhone: types.maybeNull(types.string),
    nickname: types.maybeNull(types.string),
    otherPhone: types.maybeNull(types.string),
    otherPhoneType: types.maybeNull(types.string),
    personalEmail: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    stateOrTown__c: types.maybeNull(types.string),
    taxRegion: types.maybeNull(types.string),
    workEmail: types.maybeNull(types.string),
    workPhone: types.maybeNull(types.string),
    zipCode: types.maybeNull(types.string),
  });
