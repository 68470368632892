import { getRoot, types } from 'mobx-state-tree';
import { getInvoiceData } from 'api/invoice';
import { BUNDLE_TYPE_INDEX } from 'utils/bundles';
import { setProductTypeIds } from 'utils/mobx';
import InvoiceAccount from './models/InvoiceAccount';
import InvoiceProducts from './models/InvoiceProducts';
import Subscription from './models/Subscription';

export const InvoiceStore = types
  .model('InvoiceStore', {
    account: types.optional(InvoiceAccount, {}),
    products: types.optional(InvoiceProducts, {}),
    subscription: types.optional(Subscription, {}),
    tracker: types.maybeNull(types.model({ status: types.maybe(types.string) })),
    usedCreditBalance: types.optional(types.number, 0),
    fetchingInvoice: types.optional(types.boolean, false),
    Referral_Code__c: types.maybeNull(types.string),
  })
  .views(self => ({
    get cartItems() {
      const items = {};
      const { products } = getRoot(self).invoiceStore;

      [items.membership] = products.memberships;

      // eslint-disable-next-line
      items.bundle = products[Object.values(BUNDLE_TYPE_INDEX).filter(value => products[value])] || null;

      items.pods = {
        pod: products.pod,
        qty: products.pod ? products.pod.quantity : 0,
      };

      items.superPods = {
        pod: products.superPod,
        qty: products.superPod ? products.superPod.quantity : 0,
      };

      items.powerPods = {
        pod: products.powerPod,
        qty: products.powerPod ? products.powerPod.quantity : 0,
      };

      items.smartThings = {
        pod: products.smartThings,
        qty: products.smartThings ? products.smartThings.quantity : 0,
      };

      return items;
    },
    get discount() {
      const { country } = getRoot(self);
      if (country !== 'ch') {
        return (self.subtotal + parseFloat(self.shippingWithoutDiscount) + self.subscription.tax)
            - self.subscription.computedPrice
            - self.referralCredits;
      }
      return (self.subtotal + parseFloat(self.shippingWithoutDiscount) + self.subscription.tax)
        - self.subscription.computedPrice;
    },
    get referralCredits() {
      return Math.abs(self.usedCreditBalance);
    },
    get membership() {
      const { products } = getRoot(self).invoiceStore;
      const [membership] = products.memberships;
      if (membership) {
        return membership;
      }
      return null;
    },
    get subtotal() {
      let subtotal = 0;
      const { products } = getRoot(self).invoiceStore;
      const [membership] = products.memberships;

      if (membership) {
        subtotal += membership.price;
      }

      // eslint-disable-next-line
      const bundle = products[Object.values(BUNDLE_TYPE_INDEX).filter(value => products[value])] || null;

      if (bundle) {
        subtotal += bundle.totalPrice;
      }

      if (products.pod) {
        subtotal += products.pod.price * products.pod.quantity;
      }

      if (products.superPod) {
        subtotal += products.superPod.price * products.superPod.quantity;
      }

      if (products.powerPod) {
        subtotal += products.powerPod.price * products.powerPod.quantity;
      }

      return subtotal;
    },
    get selectedBundle() {
      const { products } = getRoot(self).invoiceStore;
      const [typeId] = Object.values(BUNDLE_TYPE_INDEX).filter(value => products[value]) || null;
      return typeId;
    },
    get shipping() {
      const { products } = getRoot(self).invoiceStore;
      let method = self.shippingMethod;
      if (method === '') {
        method = products.shippingFees[0].metaMethod;
      }

      let shipping = products.shippingFees.find(sf => sf.metaMethod === method);
      if (!shipping) [shipping] = products.shippingFees;
      if (self.subscription.multiplier) {
        return shipping.price - (shipping.price * self.subscription.multiplier);
      }
      return shipping.price;
    },
    get shippingWithoutDiscount() {
      const products = getRoot(self).productsStore.localProducts;
      let method = self.shippingMethod;
      if (method === '') {
        method = products.shippingFees[0].metaMethod;
      }

      let shipping = products.shippingFees.find(sf => sf.metaMethod === method);
      if (!shipping) [shipping] = products.shippingFees;
      return shipping.price;
    },
    get shippingMethod() {
      const { products } = getRoot(self).invoiceStore;
      const method = products.shippingFees[0].metaMethod;

      return method;
    },
    get currencySign() {
      if (getRoot(self).invoiceStore.account.country === 'United States') {
        return '$';
      } if (getRoot(self).invoiceStore.account.country === 'United Kingdom') {
        return '£';
      } if (getRoot(self).invoiceStore.account.country === 'Germany') {
        return '€';
      } if (getRoot(self).invoiceStore.account.country === 'Switzerland') {
        return 'CHF';
      }
      if (getRoot(self).invoiceStore.account.country === 'Belgium') {
        return '€';
      }
      return '';
    },
    get currency() {
      if (getRoot(self).invoiceStore.account.country === 'United States') {
        return 'USD';
      } if (getRoot(self).invoiceStore.account.country === 'United Kingdom') {
        return 'GBP';
      } if (getRoot(self).invoiceStore.account.country === 'Germany') {
        return 'EUR';
      } if (getRoot(self).invoiceStore.account.country === 'Switzerland') {
        return 'CHF';
      }
      if (getRoot(self).invoiceStore.account.country === 'Belgium') {
        return 'EUR';
      }
      return '';
    },

    get hasShippableProducts() {
      const { products } = getRoot(self).invoiceStore;

      // eslint-disable-next-line
      const bundle = products[Object.values(BUNDLE_TYPE_INDEX).filter(value => products[value])] || null;

      if (bundle) {
        return true;
      }
      if (products.pod) {
        return true;
      }

      if (products.superPod) {
        return true;
      }

      if (products.powerPod) {
        return true;
      }
      return false;
    },
  }))
  .actions(self => ({
    async fetchInvoiceData(invoiceId) {
      self.fetchingInvoice = true;
      try {
        const response = await getInvoiceData(invoiceId);
        response.invoice.products = setProductTypeIds(response.invoice.products);
        self.fetchInvoiceDataSuccess(response);
      } catch (error) {
        self.fetchInvoiceDataError(error);
      }
    },

    fetchInvoiceDataSuccess(data) {
      Object.assign(self, data.invoice);
      self.fetchingInvoice = false;
    },

    fetchInvoiceDataError(error) {
      console.error(error);
      self.fetchingInvoice = false;
    },
  }));
