import { types } from 'mobx-state-tree';
import Pricing from './Pricing';

export default types
  .model('ProductRatePlanCharge', {
    id: types.string,
    name: types.string,
    description: types.string,
    // TODO: This field is not in response:
    pricing: types.optional(types.array(Pricing), []),
    // TODO: This field is not in response:
    taxMode: types.maybeNull(types.string),
    InStock__c: types.maybeNull(types.string),
    // TODO: This field is not in response:
    StockStatus__c: types.maybeNull(types.string),
  });
