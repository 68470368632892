import { types } from 'mobx-state-tree';

export default types.model('CreditCard', {
  id: types.maybeNull(types.string),
  ibanNumber: types.maybeNull(types.string),
  defaultPaymentMethod: types.maybeNull(types.boolean),
  cardType: types.maybeNull(types.string),
  cardNumber: types.maybeNull(types.string),
  expirationMonth: types.maybeNull(types.number),
  expirationYear: types.maybeNull(types.number),
  cardHolderInfo: types.model({
    addressLine1: types.maybeNull(types.string),
    addressLine2: types.maybeNull(types.string),
    cardHolderName: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    zipCode: types.maybeNull(types.string),
  }),
});
