import axios from 'axios';
import { BACKEND_URL } from 'env';

export const fetchInvoices = async (accessToken) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/api/account/membership-invoices`,
      responseType: 'json',
      headers: { Authorization: accessToken },
    });
    return response.data;
  } catch (err) {
    if (err.status && err.status !== 404) {
      console.error(err);
    }
    throw err;
  }
};

export const getInvoiceData = async (invoiceId) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/invoice/${invoiceId}`,
      responseType: 'json',
    });
    return response.data;
  } catch (err) {
    return err.response;
  }
};

export const verifyInvoice = async (invoiceToken = '') => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BACKEND_URL}/invoice-token-verify/${invoiceToken}`,
      responseType: 'json',
    });
    return response.data;
  } catch (err) {
    console.log('err', err);
    return Promise.reject(err.response);
  }
};
