import { types } from 'mobx-state-tree';
import { postMagicLink } from '../api';

export const MagicLinkStore = types
  .model('MagicLinkStore', {
    sending: false,
    sent: false,
    status: types.optional(types.number, 0),
    message: types.optional(types.string, ''),
  })
  .actions(self => ({
    async postMagicLink(email, redirectUrl = '/') {
      self.sending = true;
      const response = postMagicLink(email, redirectUrl);
      response.then(self.postMagicLinkSuccess, self.postMagicLinkError);
    },

    postMagicLinkSuccess(data) {
      self.sending = false;
      self.sent = true;
      if (data.sendResult === 'SENT') {
        self.status = 200;
      } else {
        self.status = data.status;
        self.message = data.data && data.data.message ? data.data.message : '';
      }
    },

    postMagicLinkError(/* data */) {
      self.sending = false;
    },

    resetStatus() {
      self.status = 0;
      self.message = '';
    },

  }));
